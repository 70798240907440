<script setup>
import { useIMask } from "vue-imask";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  kind: {
    type: String,
    default: "text",
  },
});

const emit = defineEmits(["update:modelValue"]);

const { el: phoneEl, masked: phoneMasked } = useIMask({
  mask: "000-000-0000",
});

const { el: zipEl, masked: zipMasked } = useIMask({
  mask: "00000",
});

const { el: numberEl, masked: numberMasked } = useIMask({
  mask: /^\d+$/,
});

const onChange = (event) => {
  if (props.kind === "phone") {
    emit("update:modelValue", phoneMasked.value);
  }

  if (props.kind === "zipcode") {
    emit("update:modelValue", zipMasked.value);
  }

  if (props.kind === "number") {
    emit("update:modelValue", numberMasked.value);
  }

  if (props.kind === "text") {
    emit("update:modelValue", event.target.value);
  }
};

const refHandle = (element) => {
  if (props.kind === "text") return;

  if (props.kind === "phone") {
    phoneEl.value = element;
  }

  if (props.kind === "zipcode") {
    zipEl.value = element;
  }

  if (props.kind === "number") {
    numberEl.value = element;
  }
};
</script>

<template>
  <input
    :ref="refHandle"
    :type="type"
    class="form-input min-h-[48px] w-full border-none bg-[#F5F5F5] focus:ring-[#d43da5]"
    :class="{ 'sm:max-w-[460px]': !fullWidth }"
    :value="modelValue"
    :placeholder="placeholder"
    @change="onChange"
  />
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
