<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, numeric } from "@vuelidate/validators";
import { requiredValidation } from "@/utils/validations";
import { numericOnly } from "@/utils/common";

import useMainStore from "~/apps/stores/main";
import useFormStore from "~/apps/stores/form";

import { restaurantsValues } from "~/apps/stores/form/restaurants";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormSelect from "~/apps/components/Form/FormSelect.vue";
import FormDatepicker from "~/apps/components/Form/FormDatepicker.vue";
import FormCheckbox from "~/apps/components/Form/FormCheckbox.vue";
import FormButton from "~/apps/components/Form/FormButton.vue";
import FormTip from "@/apps/components/Form/FormTip.vue";
import FormMultipleSelect from "~/apps/components/Form/FormMultipleSelect.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

// Select options
const haveLiveMusicOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const averageDaysPerWeekOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "1",
  },
  {
    value: "2",
  },
  {
    value: "3",
  },
  {
    value: "4",
  },
  {
    value: "5",
  },
  {
    value: "6",
  },
  {
    value: "7",
  },
];

const liveMusicYearRoundOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const recordedMusicYearRoundOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const monthPerYearOptions = [
  {
    value: "July",
  },
  {
    value: "August",
  },
  {
    value: "September",
  },
  {
    value: "October",
  },
  {
    value: "November",
  },
  {
    value: "December",
  },
  {
    value: "January",
  },
  {
    value: "February",
  },
  {
    value: "March",
  },
  {
    value: "April",
  },
  {
    value: "May",
  },
  {
    value: "June",
  },
];

const coverOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "No",
  },
  {
    value: "Yes - Under $50",
  },
  {
    value: "Yes - Over $50",
  },
];

const danceFloorOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "No",
  },
  {
    value: "Yes",
  },
];

const recordedMusicOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];
const musicOnWebsiteOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

// Live Music conditions
const isLiveMusic = computed(() => {
  return restaurantsValues.haveLiveMusic.value === "Yes";
});

const isAverageLiveMusicDaysPerWeekVisible = computed(
  () =>
    (restaurantsValues.haveLiveMusic.value === "Yes" && restaurantsValues.liveMusicYearRound.value === "Yes") ||
    (restaurantsValues.haveLiveMusic.value === "Yes" &&
      restaurantsValues.liveMusicYearRound.value === "No" &&
      restaurantsValues.liveMusicMonthsPerYear.value &&
      !restaurantsValues.lessThan5DaysPerYear.value)
);

const isLiveMusicMonthsPerYearVisible = computed(() => {
  return (
    restaurantsValues.haveLiveMusic.value === "Yes" &&
    restaurantsValues.liveMusicYearRound.value === "No" &&
    !restaurantsValues.lessThan5DaysPerYear.value
  );
});

const isLiveMusicLessThan5DaysPerYearVisible = computed(() => {
  return restaurantsValues.haveLiveMusic.value === "Yes" && restaurantsValues.liveMusicYearRound.value === "No";
});

const lessThan5DaysPerYearClass = computed(() =>
  !restaurantsValues.lessThan5DaysPerYear.value ? "sm:col-span-12" : ""
);

const isDateFieldsVisible = computed(
  () =>
    restaurantsValues.haveLiveMusic.value === "Yes" &&
    restaurantsValues.liveMusicYearRound.value === "No" &&
    restaurantsValues.lessThan5DaysPerYear.value
);

// Add/remove date field methods
const addDateField = () => {
  restaurantsValues.dates.value.push({
    value: "",
  });
};

const removeDateField = (index) => {
  restaurantsValues.dates.value.splice(index, 1);
};

const allDateFieldsVisible = computed(() => {
  return restaurantsValues.dates.value.length >= 5;
});

// Recorded Music conditions
const isRecordedMusic = computed(() => {
  return restaurantsValues.recordedMusic.value === "Yes";
});

const isRecordedMusicMonthsPerYearVisible = computed(() => {
  return restaurantsValues.recordedMusic.value === "Yes" && restaurantsValues.recordedMusicYearRound.value === "No";
});

// Validation rules
const liveMusicYearRoundRules = computed(() =>
  isLiveMusic.value
    ? {
        liveMusicYearRound: {
          required: requiredValidation("", "Do You Use Live Music Year Round?"),
        },
      }
    : {}
);

const recordedMusicYearRoundRules = computed(() =>
  isRecordedMusic.value
    ? {
        recordedMusicYearRound: {
          required: requiredValidation("", "Do You Use Recorded Music Year Round?"),
        },
      }
    : {}
);

const haveCoverRules = computed(() =>
  isRecordedMusic.value
    ? {
        haveCover: {
          required: requiredValidation("", "Do You Ever Charge a Door/Cover Fee?"),
        },
      }
    : {}
);

const haveDanceFloorRules = computed(() =>
  isRecordedMusic.value
    ? {
        haveDanceFloor: {
          required: requiredValidation("", "Do You Have a Dance Floor?"),
        },
      }
    : {}
);

const liveMusicMonthPerYearRules = computed(() =>
  isLiveMusicMonthsPerYearVisible.value
    ? {
        liveMusicMonthsPerYear: {
          required: requiredValidation("", "Which Months Do You Use Live Music?"),
        },
      }
    : {}
);

const recordedMusicMonthPerYearRules = computed(() =>
  isRecordedMusicMonthsPerYearVisible.value
    ? {
        recordedMusicMonthsPerYear: {
          required: requiredValidation("", "Which Months Do You Use Recorded Music?"),
        },
      }
    : {}
);

const averageDaysPerWeekRules = computed(() =>
  isAverageLiveMusicDaysPerWeekVisible.value
    ? {
        averageDaysPerWeek: {
          required: requiredValidation("Number Of Days Per Week"),
        },
      }
    : {}
);

const datesRules = computed(() => {
  const rules = {};

  if (!isDateFieldsVisible.value) {
    return { dates: rules };
  }

  restaurantsValues.dates.value.forEach((date, index) => {
    rules[index] = {
      value: {
        required: requiredValidation(`Date ${index + 1}`),
        uniqueDate: helpers.withMessage("Dates Must Be Unique", (value) => {
          const dates = [...restaurantsValues.dates.value].map((date) => date.value);
          return dates.filter((date) => date === value).length === 1;
        }),
      },
    };
  });

  return { dates: rules };
});

const atLeastOneYesRule = () => {
  return (
    restaurantsValues.haveLiveMusic.value === "Yes" ||
    restaurantsValues.recordedMusic.value === "Yes" ||
    restaurantsValues.musicOnWebsite.value === "Yes"
  );
};

const rules = computed(() => ({
  maxOccupancy: {
    required: requiredValidation("Max Occupancy"),
    numeric: helpers.withMessage("Max Occupancy can only contain digits.", numeric),
  },
  haveLiveMusic: {
    required: requiredValidation("", "Do You Use Live Music?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  ...liveMusicYearRoundRules.value,
  ...averageDaysPerWeekRules.value,
  ...datesRules.value,
  ...liveMusicMonthPerYearRules.value,
  recordedMusic: {
    required: requiredValidation("", "Do You Use Recorded Music?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  ...haveCoverRules.value,
  ...haveDanceFloorRules.value,
  ...recordedMusicYearRoundRules.value,
  ...recordedMusicMonthPerYearRules.value,
  musicOnWebsite: {
    required: requiredValidation("", "Do You Have Music On Your Website?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  restaurantMusicStartDate: {
    required: requiredValidation("Restaurant Music Start Date"),
  },
}));

const v$ = useVuelidate(rules, restaurantsValues);
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("MusicInYourEstablishment");
});

const resetLiveMusic = () => {
  restaurantsValues.liveMusicYearRound.value = "";
  resetLiveMusicMonthsPerYear();
};

const resetLiveMusicMonthsPerYear = () => {
  restaurantsValues.liveMusicMonthsPerYear.value = "";
  restaurantsValues.dates.value = [];
  restaurantsValues.averageDaysPerWeek.value = "";
};

const resetRecordedMusic = () => {
  restaurantsValues.haveCover.value = "";
  restaurantsValues.haveDanceFloor.value = "";
  restaurantsValues.recordedMusicYearRound.value = "";
  restaurantsValues.recordedMusicMonthsPerYear.value = "";
};
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem>
      <template #label>What is your max occupancy?*</template>
      <template #field>
        <FormInput v-model="restaurantsValues.maxOccupancy.value" type="number" required @keydown="numericOnly" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Do you have live music?</template>
      <template #field>
        <FormSelect
          v-model="restaurantsValues.haveLiveMusic.value"
          :options="haveLiveMusicOptions"
          @update:model-value="resetLiveMusic"
        />
      </template>
    </FormItem>
    <FormItem v-if="isLiveMusic" class="sm:!col-span-12">
      <template #label>Do you use live music year round?*</template>
      <template #field>
        <FormSelect
          v-model="restaurantsValues.liveMusicYearRound.value"
          :options="liveMusicYearRoundOptions"
          @update:model-value="resetLiveMusicMonthsPerYear"
          full-width
        />
      </template>
    </FormItem>
    <p v-if="isLiveMusicMonthsPerYearVisible" class="text-sm sm:!col-span-12">
      Since Your Business Does Not Use Live Music Year Round...
    </p>
    <FormItem v-if="isLiveMusicMonthsPerYearVisible" class="sm:!col-span-12">
      <template #label>Which Months Do You Use Live Music?*</template>
      <template #field>
        <FormMultipleSelect
          v-model="restaurantsValues.liveMusicMonthsPerYear.value"
          :options="monthPerYearOptions"
          full-width
        />
      </template>
    </FormItem>
    <FormItem v-if="isLiveMusicLessThan5DaysPerYearVisible" :class="lessThan5DaysPerYearClass">
      <template #field>
        <FormCheckbox
          v-model="restaurantsValues.lessThan5DaysPerYear.value"
          @update:model-value="resetLiveMusicMonthsPerYear"
          >Less Than 5 Dates Per Year
        </FormCheckbox>
      </template>
    </FormItem>
    <FormItem v-if="isAverageLiveMusicDaysPerWeekVisible" class="sm:col-span-12">
      <template #label>On average, how many days per week does your establishment have live music?*</template>
      <template #field>
        <FormSelect
          v-model="restaurantsValues.averageDaysPerWeek.value"
          :options="averageDaysPerWeekOptions"
          full-width
        />
      </template>
    </FormItem>
    <FormItem v-if="isDateFieldsVisible" class="sm:text-right">
      <template #field>
        <FormButton v-if="!allDateFieldsVisible" @click="addDateField">Add Date</FormButton>
      </template>
    </FormItem>
    <div v-if="isDateFieldsVisible" class="grid gap-y-4 sm:col-span-12">
      <FormItem v-for="(date, index) in restaurantsValues.dates.value" :key="index" class="sm:col-span-12">
        <template #field>
          <div class="relative">
            <FormDatepicker v-model="date.value" :clearable="false" required />
            <button
              v-if="index !== 0 || restaurantsValues.dates.value.length > 1"
              class="absolute top-6 right-0 inline-flex"
              @click.prevent="removeDateField(index)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                class="dp__icon dp__clear_icon dp__input_icons"
              >
                <path
                  d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                ></path>
                <path
                  d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                ></path>
              </svg>
            </button>
          </div>
        </template>
      </FormItem>
    </div>
    <FormItem>
      <template #label>Do you use recorded music?</template>
      <template #tip>
        <FormTip>
          <p>Example: DJ, Karaoke, Streaming, Digital, TV, DVDs, Radio.</p>
        </FormTip>
      </template>
      <template #field>
        <FormSelect
          v-model="restaurantsValues.recordedMusic.value"
          :options="recordedMusicOptions"
          @update:model-value="resetRecordedMusic"
        />
      </template>
    </FormItem>
    <FormItem v-if="isRecordedMusic" class="sm:!col-span-12" :capitalize="false">
      <template #label>Do You Ever Charge a Door/Cover Fee?</template>
      <template #field>
        <FormSelect v-model="restaurantsValues.haveCover.value" :options="coverOptions" full-width />
      </template>
    </FormItem>
    <FormItem v-if="isRecordedMusic" class="sm:!col-span-12">
      <template #label>Do you have a dance floor?</template>
      <template #field>
        <FormSelect v-model="restaurantsValues.haveDanceFloor.value" :options="danceFloorOptions" full-width />
      </template>
    </FormItem>
    <FormItem v-if="isRecordedMusic" class="sm:!col-span-12">
      <template #label>Do you use recorded music year round?</template>
      <template #field>
        <FormSelect
          v-model="restaurantsValues.recordedMusicYearRound.value"
          :options="recordedMusicYearRoundOptions"
          full-width
        />
      </template>
    </FormItem>
    <FormItem v-if="isRecordedMusicMonthsPerYearVisible" class="sm:!col-span-12">
      <template #label>Which Months Do You Use Recorded Music?*</template>
      <template #field>
        <FormMultipleSelect
          v-model="restaurantsValues.recordedMusicMonthsPerYear.value"
          :options="monthPerYearOptions"
          full-width
        />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Do you have music on your website?</template>
      <template #field>
        <FormSelect v-model="restaurantsValues.musicOnWebsite.value" :options="musicOnWebsiteOptions" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>When did your restaurant start using music?*</template>
      <template #field>
        <FormDatepicker v-model="restaurantsValues.restaurantMusicStartDate.value" month-picker required />
      </template>
    </FormItem>
  </FormWrapper>
</template>
