import { email, helpers, maxLength, minLength, numeric, required } from "@vuelidate/validators";

const alpha = helpers.regex(/^[a-zA-Z ]*$/);
const url = helpers.regex(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/);

const requiredValidation = (valueName, customValue) =>
  helpers.withMessage(customValue ? customValue : `${valueName} is Required.`, required);
const zipcodeValidation = (valueName = "Zip Code") => ({
  required: requiredValidation(valueName),
  numeric: helpers.withMessage(`${valueName} Can Only Contain Digits.`, numeric),
  minLength: helpers.withMessage(`${valueName} Should Be 5 Digits Long`, minLength(5)),
  maxLength: helpers.withMessage(`${valueName} Should Be 5 Digits Long`, maxLength(5)),
});
const firstNameValidation = {
  required: requiredValidation("First Name"),
  alpha: helpers.withMessage("First Name Can Only Contain Letters.", alpha),
};
const lastNameValidation = {
  required: requiredValidation("Last Name"),
  alpha: helpers.withMessage("Last Name Can Only Contain Letters.", alpha),
};
const titleValidation = {
  required: requiredValidation("Title"),
};
const emailValidation = {
  required: requiredValidation("Email"),
  email: helpers.withMessage("Email is Not Valid.", email),
};
const phoneValidation = {
  required: requiredValidation("Phone"),
  minLength: helpers.withMessage("Phone Should Be 10 Digits Long", minLength(12)),
  maxLength: helpers.withMessage("Phone Should Be 10 Digits Long", maxLength(12)),
};
const urlValidation = {
  url: helpers.withMessage("Url is Not Valid.", url),
};
const bankRoutingNumber = (valueName) => {
  return {
    required: requiredValidation(valueName),
    numeric: helpers.withMessage(`${valueName} Can Only Contain Digits.`, numeric),
    minLength: helpers.withMessage(`${valueName} Should Be 9 Digits`, minLength(9)),
    maxLength: helpers.withMessage(`${valueName} Should Be 9 Digits`, maxLength(9)),
  };
};
const bankAccountNumber = (valueName) => {
  return {
    required: requiredValidation(valueName),
    numeric: helpers.withMessage(`${valueName} Can Only Contain Digits.`, numeric),
    minLength: helpers.withMessage(`${valueName} Should Be At Least 10 Digits`, minLength(10)),
    maxLength: helpers.withMessage(`${valueName} Should Be At Most 12 Digits`, maxLength(12)),
  };
};

export {
  requiredValidation,
  zipcodeValidation,
  firstNameValidation,
  lastNameValidation,
  titleValidation,
  emailValidation,
  phoneValidation,
  bankRoutingNumber,
  bankAccountNumber,
  urlValidation,
};
