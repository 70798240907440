const stepCount = {
  "In-Home Fitness": 5,
  "Web App": 5,
  Restaurants: 5,
  Hotels: 5,
  Other: 3,
  "Retail Store": 3,
  "Special Events": 3,
  "Country Clubs": 3,
  Radio: 3,
  "Residential Facility": 4,
  "Fitness Center": 4,
  "Church Or Ministry": 2,
};

export { stepCount };
