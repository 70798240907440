import { ref } from "vue";

const webAppValues = {
  websiteAppName: ref(""),
  primaryUrl: ref(""),
  launchDate: ref(""),
  zipcode: ref(""),
  legalEntityName: ref(""),
  legalEntityAddress: ref(""),
  addressLine2: ref(""),
  city: ref(""),
  state: ref(""),
  similarBillingAddress: ref(""),
  billingStreetAddress: ref(""),
  billingCity: ref(""),
  billingState: ref(""),
  billingZipcode: ref(""),
  nonProfitTaxExempt: ref(""),
  lessRevenue: ref(""),
  thirdParty: ref(""),
  haveSubscription: ref(""),
  contentType: ref(""),
  musicEntertainmentSports: ref(""),
  authorizedPlatforms: {
    field1: ref(""),
    field2: ref(""),
    field3: ref(""),
    field4: ref(""),
    field5: ref(""),
  },
  distributedContent: {
    musicIntensive: ref(false),
    generalEntertainment: ref(false),
    newsTalkSports: ref(false),
  },
  distributionDesignatedPeriods: ref([]),
  athDesignatedPeriods: ref([]),
  eBillingEmail: ref(""),
};

export { webAppValues };
