<script setup>
import { onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
  requiredValidation,
  titleValidation,
  zipcodeValidation,
} from "~/utils/validations";

import useMainStore from "~/apps/stores/main";
import useFormStore from "~/apps/stores/form";

import { userValues } from "~/apps/stores/form/user";
import { hotelsValues } from "~/apps/stores/form/hotels";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormSelect from "~/apps/components/Form/FormSelect.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const rules = {
  hotelName: {
    required: requiredValidation("Hotel Name"),
  },
  firstName: {
    ...firstNameValidation,
  },
  lastName: {
    ...lastNameValidation,
  },
  title: {
    ...titleValidation,
  },
  email: {
    ...emailValidation,
  },
  phoneNumber: {
    ...phoneValidation,
  },
  zipcode: {
    ...zipcodeValidation("Hotel Zip Code"),
  },
};

const v$ = useVuelidate(rules, { ...userValues, ...hotelsValues }, { $autoDirty: true });
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourself");
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem class="sm:col-span-12">
      <template #label>Hotel Name*</template>
      <template #field>
        <FormInput v-model="hotelsValues.hotelName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>First Name*</template>
      <template #field>
        <FormInput v-model="userValues.firstName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Last Name*</template>
      <template #field>
        <FormInput v-model="userValues.lastName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Title*</template>
      <template #field>
        <FormInput v-model="userValues.title.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Email*</template>
      <template #field>
        <FormInput v-model="userValues.email.value" type="email" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Phone Number*</template>
      <template #field>
        <FormInput v-model="userValues.phoneNumber.value" type="text" kind="phone" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Hotel Zip Code*</template>
      <template #field>
        <FormInput v-model="hotelsValues.zipcode.value" type="text" kind="zipcode" required pattern="[0-9]*" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>How many locations?</template>
      <template #field>
        <FormSelect
          v-model="hotelsValues.locationsNumber.value"
          :options="[
            {
              value: '1',
            },
            {
              value: '2',
            },
            {
              value: '3',
            },
            {
              value: '4',
            },
            {
              value: '5',
            },
            {
              value: '6',
            },
            {
              value: '7',
            },
            {
              value: '8',
            },
            {
              value: '9',
            },
            {
              label: '10+',
              value: '10',
            },
          ]"
        />
      </template>
    </FormItem>
  </FormWrapper>
</template>
