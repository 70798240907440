import { ref } from "vue";

const hotelsValues = {
  hotelName: ref(""),
  zipcode: ref(""),
  locationsNumber: ref("1"),
  streetAddress: ref(""),
  addressLine2: ref(""),
  city: ref(""),
  state: ref(""),
  similarBillingAddress: ref(""),
  billingStreetAddress: ref(""),
  billingCity: ref(""),
  billingState: ref(""),
  billingZipcode: ref(""),
  similarLegalEntityName: ref(""),
  legalEntityName: ref(""),
  haveLiveMusic: ref(""),
  averageDaysPerWeek: ref(""),
  recordedMusic: ref(""),
  musicOnWebsite: ref(""),
  ahlaMember: ref(""),
  ahlaMemberId: ref(""),
  numberOfRooms: ref(""),
  averagePricePerRoom: ref(""),
  hotelMusicStartDate: ref(""),
  eBillingEmail: ref(""),
};

export { hotelsValues };
