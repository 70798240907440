<script setup>
import { onMounted, computed } from "vue";

import usePaymentStore from "~/apps/stores/payment";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";

const paymentStore = usePaymentStore();

const hostedFieldsConfig = {
  styles: {
    input: {
      "font-size": "16px",
      "font-family": "'Roboto Condensed', sans-serif",
      "font-weight": "lighter",
      color: "#ccc",
    },
    ":focus": {
      color: "black",
    },
    ".valid": {
      color: "#8bdda8",
    },
  },
  fields: {
    number: {
      selector: "#card-number",
      placeholder: "1111 1111 1111 1111",
    },
    cvv: {
      selector: "#cvv",
      placeholder: "123",
    },
    expirationDate: {
      selector: "#expiration-date",
      placeholder: "MM/YYYY",
    },
    postalCode: {
      selector: "#zip-code",
      placeholder: "",
    },
  },
};

const isReady = computed(() => {
  return !paymentStore.paymentInstance;
});

onMounted(async () => {
  await paymentStore.createCreditCardFields(hostedFieldsConfig);
});
</script>

<template>
  <FormWrapper id="credit-card-fields" class="relative mb-10" name="creditCardForm">
    <div
      v-show="isReady"
      class="absolute bottom-0 left-0 z-50 flex h-48 w-full flex-col items-center justify-center overflow-hidden bg-gray-400 opacity-75"
    >
      <div
        class="mb-4 h-12 w-12 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-400 ease-linear"
      ></div>
      <span class="text-center text-xl font-semibold text-white">Loading...</span>
    </div>

    <label class="col-span-12">
      <span class="flex justify-between text-[#494949]">Card Number*</span>
      <div
        id="card-number"
        class="hosted-field form-input h-14 w-full border-none bg-[#F5F5F5] focus:ring-[#d43da5]"
      ></div>
    </label>

    <label class="col-span-4">
      <span class="flex justify-between text-[#494949]">Expiration date*</span>
      <div
        id="expiration-date"
        class="hosted-field form-input h-14 w-full border-none bg-[#F5F5F5] focus:ring-[#d43da5]"
      ></div>
    </label>

    <label class="col-span-4">
      <span class="flex justify-between text-[#494949]">CVV*</span>
      <div id="cvv" class="hosted-field form-input h-14 w-full border-none bg-[#F5F5F5] focus:ring-[#d43da5]"></div>
    </label>

    <label class="col-span-4">
      <span class="flex justify-between text-[#494949]">ZIP*</span>
      <div
        id="zip-code"
        class="hosted-field form-input h-14 w-full border-none bg-[#F5F5F5] focus:ring-[#d43da5]"
      ></div>
    </label>
  </FormWrapper>
</template>

<style>
#braintree-hosted-field-number {
  background-color: transparent;
}
</style>
