<script setup>
import { computed } from "vue";

import useFormStore from "../../stores/form";

const formStore = useFormStore();

const errors = computed(() => formStore.errors);
</script>

<template>
  <ul v-if="errors.length > 0" class="mb-10">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <li v-for="error in errors" :key="error" class="text-sm text-[#EE3D42]" v-html="error"></li>
  </ul>
</template>
