import { ref } from "vue";

const restaurantsValues = {
  establishmentName: ref(""),
  zipcode: ref(""),
  locationsNumber: ref("1"),
  streetAddress: ref(""),
  addressLine2: ref(""),
  city: ref(""),
  state: ref(""),
  similarBillingAddress: ref(""),
  billingStreetAddress: ref(""),
  billingCity: ref(""),
  billingState: ref(""),
  billingZipcode: ref(""),
  legalEntityName: ref(""),
  maxOccupancy: ref(""),
  haveLiveMusic: ref(""),
  averageDaysPerWeek: ref(""),
  lessThan5DaysPerYear: ref(false),
  dates: ref([
    {
      value: "",
    },
  ]),
  haveCover: ref(""),
  haveDanceFloor: ref(""),
  recordedMusic: ref(""),
  musicOnWebsite: ref(""),
  liveMusicYearRound: ref(""),
  recordedMusicYearRound: ref(""),
  liveMusicMonthsPerYear: ref(""),
  recordedMusicMonthsPerYear: ref(""),
  restaurantMusicStartDate: ref(""),
  eBillingEmail: ref(""),
};

export { restaurantsValues };
