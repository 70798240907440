<script setup>
import useMainStore from "~/apps/stores/main";

import TheButton from "~/apps/components/TheButton.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import FormErrors from "~/apps/components/Form/FormErrors.vue";

import ChurchOrMinistry from "./ChurchOrMinistry/AboutChurchOrMinistry.vue";

const mainStore = useMainStore();

const back = () => {
  mainStore.previousStep();
};

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};
</script>

<template>
  <ChurchOrMinistry />

  <FormErrors />
  <TheButtonWrapper>
    <TheButton @click="back">Back</TheButton>
    <TheButton kind="red" role="link" @click="openInNewTab('https://christiancopyrightsolutions.com/')">
      Visit CCS
    </TheButton>
  </TheButtonWrapper>
</template>
