<script setup>
import { computed } from "vue";

import useMainStore from "../stores/main";

const mainStore = useMainStore();

const description = computed(() => mainStore.description);
</script>

<template>
  <!-- eslint-disable -->
  <div v-if="description" class="prose prose-ol:list-decimal mb-4 text-lg text-[#494949]" v-html="description"></div>
  <!-- eslint-enable -->
</template>
