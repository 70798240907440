import { ref } from "vue";

const radioValues = {
  websiteAppNameCallLetters: ref(""),
  zipcode: ref(""),
  websiteAppName: ref(""),
  launchDate: ref(""),
  companyName: ref(""),
};

export { radioValues };
