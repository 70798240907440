<script setup>
import { computed, onMounted } from "vue";

import RestaurantsImage from "../../images/Restaurants.svg";
import HotelsImage from "../../images/Hotels.svg";
import FitnessCenterImage from "../../images/Fitness-Center.svg";
import ResidentialFacilityImage from "../../images/Residential-Facility.svg";
import CountryClubsImage from "../../images/Country-Clubs.svg";
import SpecialEventsImage from "../../images/Special-Events.svg";
import RadioImage from "../../images/Radio.svg";
import RetailStoreImage from "../../images/Retail-Store.svg";
import OtherImage from "../../images/Other.svg";
import WebAppImage from "../../images/Web-App.svg";
import MarkImage from "../../images/Mark.svg";
import HomeFitness from "../../images/Home-Fitness.svg";
import ChurchOrMinistry from "../../images/ChurchOrMinistry.svg";

import useMainStore from "../stores/main";
import useFlowsStore from "../stores/flows";

import TheButtonWrapper from "../components/TheButtonWrapper.vue";
import TheButton from "../components/TheButton.vue";

const mainStore = useMainStore();
const flowsStore = useFlowsStore();
const flows = computed(() => {
  return [...flowsStore.flows].sort((a, b) => a.position - b.position);
});

const setCurrentFlow = (flow) => {
  flowsStore.setCurrentFlow(flow);
};

const next = () => {
  mainStore.gtmFirstStep(flowsStore.currentFlow.name);
  mainStore.setCurrentView("FormView");
  mainStore.nextStep();
};

const imageMap = {
  Restaurants: RestaurantsImage,
  Hotels: HotelsImage,
  "In-Home Fitness": HomeFitness,
  "Residential Facility": ResidentialFacilityImage,
  "Web App": WebAppImage,
  "Fitness Center": FitnessCenterImage,
  "Country Clubs": CountryClubsImage,
  "Special Events": SpecialEventsImage,
  Other: OtherImage,
  "Church Or Ministry": ChurchOrMinistry,
  "Retail Store": RetailStoreImage,
  Radio: RadioImage,
};

const titleMap = {
  Restaurants: "Restaurant/Bar/Nightclub",
  Hotels: "Hotel",
  "In-Home Fitness": "In-Home Fitness Website/App",
  "Residential Facility": "Residential Facility",
  "Web App": "Website/App",
  "Fitness Center": "Fitness Center",
  "Country Clubs": "Country Club",
  "Special Events": "Special Event",
  Other: "Other",
  "Church Or Ministry": "Church Or Ministry",
  "Retail Store": "Retail Store",
  Radio: "Radio/TV Station",
};

onMounted(() => {
  mainStore.setDescription();
});
</script>

<template>
  <div class="xs:grid-cols-2 my-8 grid grid-cols-1 gap-5 sm:grid-cols-4">
    <button
      v-for="flow in flows"
      :key="flow.id"
      class="relative flex flex-col items-center justify-between gap-4 bg-gray-100 px-3 py-5 opacity-40 transition-opacity focus-visible:outline-[#d43da5]"
      :class="{ 'opacity-100': flow.id === flowsStore.currentFlow?.id }"
      @click="setCurrentFlow(flow)"
    >
      <img class="h-20 w-auto object-contain" :src="imageMap[flow.name]" height="80" :alt="flow.name" />
      <span class="font-bold">{{ titleMap[flow.name] }}</span>
      <img
        class="absolute -right-4 -top-4 transition-opacity"
        :class="{
          'opacity-0': flow.id !== flowsStore.currentFlow?.id,
          'opacity-100': flow.id === flowsStore.currentFlow?.id,
        }"
        :src="MarkImage"
        alt=""
      />
    </button>
  </div>
  <TheButtonWrapper>
    <TheButton kind="red" @click="next">Next Step</TheButton>
  </TheButtonWrapper>
</template>
