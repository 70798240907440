<script setup>
import { onMounted, ref } from "vue";

import usePaymentStore from "~/apps/stores/payment";

const emit = defineEmits(["paypalComplete"]);

const paymentStore = usePaymentStore();

const isReady = ref(false);

onMounted(async () => {
  try {
    await paymentStore.createPaypalCheckout();
  } catch (error) {
    console.log("### ERROR");
    console.error(error);
  }

  // RENDER PAYPAL BUTTON
  if (paypal) {
    await paypal
      .Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,

        style: {
          disableMaxWidth: true,
        },

        createOrder: async () => {
          console.log("CREATE ORDER");
          const paymentId = await paymentStore.paymentInstance.createPayment({
            flow: "checkout",
            amount: paymentStore.license.licenseAnnualFee,
            currency: "USD",
            intent: "capture",
          });

          return paymentId;
        },

        onApprove: async (data, actions) => {
          const payload = await paymentStore.paymentInstance.tokenizePayment(data);
          paymentStore.nonce = payload.nonce;
          paymentStore.status.tokenize = true;

          await paymentStore.postTransaction();
          await paymentStore.completeLicense();

          emit("paypalComplete", true);

          return payload;
        },

        onCancel: (data) => {
          console.log("PayPal payment cancelled", JSON.stringify(data, 0, 2));
        },

        onError: (err) => {
          console.error("PayPal error", err);
        },
      })
      .render("#paypal-button");

    isReady.value = true;
  }
});
</script>

<template>
  <div class="p-12 text-center">
    <h1 v-show="!isReady" class="text-4xl font-normal">Loading...</h1>
    <div v-show="isReady" id="paypal-button"></div>
  </div>
</template>

<style></style>
