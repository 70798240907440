<script setup>
import { computed } from "vue";

import useMainStore from "../stores/main";
const mainStore = useMainStore();

const barProgress = computed(() => {
  return `${(mainStore.currentStep / mainStore.stepCount) * 100}%`;
});
</script>

<template>
  <div class="mb-7">
    <span class="mb-3 block text-xs font-bold">Step {{ mainStore.currentStep }} of {{ mainStore.stepCount }}</span>
    <div class="bar h-5 rounded-xl bg-gray-50 transition-all"></div>
  </div>
</template>

<style scoped>
.bar {
  background-image: linear-gradient(94deg, #ee3d42 0%, #d43da5 100%);
  background-size: v-bind(barProgress) 100%;
  background-repeat: no-repeat;
}
</style>
