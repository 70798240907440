<script setup>
import { computed, ref } from "vue";
import { nextStep } from "~/utils/common";

import useMainStore from "~/apps/stores/main";
import useUserStore from "~/apps/stores/user";
import useFlowsStore from "~/apps/stores/flows";

import { webAppValues } from "../../stores/form/web-app";

import TheButton from "~/apps/components/TheButton.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import FormErrors from "~/apps/components/Form/FormErrors.vue";

import TellUsAboutYourself from "./WebApp/TellUsAboutYourself.vue";
import TellUsAboutYourWebApp from "./WebApp/TellUsAboutYourWebApp.vue";
import TellUsAboutYourWebApp2 from "./WebApp/TellUsAboutYourWebApp2.vue";

const components = {
  1: TellUsAboutYourself,
  2: TellUsAboutYourWebApp,
  3: TellUsAboutYourWebApp2,
};

const mainStore = useMainStore();
const userStore = useUserStore();
const flowsStore = useFlowsStore();

const currentStep = computed(() => mainStore.currentStep);

const component = ref(null);

const back = () => {
  mainStore.previousStep();
};

const next = async () => {
  const isValid = await component.value.validate();

  if (!isValid) return;

  await nextStep(webAppValues, webAppValues.musicEntertainmentSports.value === "No");
};
</script>

<template>
  <Component :is="components[currentStep - 1]" ref="component" />
  <FormErrors />
  <TheButtonWrapper>
    <TheButton @click="back">Back</TheButton>
    <TheButton kind="red" @click="next">Next Step</TheButton>
  </TheButtonWrapper>
</template>
