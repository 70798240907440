// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
import { createApp } from "vue";
import { createPinia } from "pinia";
import { capitalize } from "@/utils/common";
import { createGtm } from "@gtm-support/vue-gtm";

import MainApp from "@/apps/MainApp.vue";

console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log("Visit the guide for more information: ", "https://vite-ruby.netlify.app/guide/rails");

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

const app = createApp(MainApp);

app.directive("capitalize", {
  beforeMount(el, { value }) {
    if (!value) return;

    el.textContent = capitalize(el.textContent);
  },
  updated(el, { value }) {
    if (!value) return;

    el.textContent = capitalize(el.textContent);
  },
});

app.use(createPinia());

app.use(
  createGtm({
    id: import.meta.env.VITE_APP_GTM_KEY || "GTM-XXXXXXXX",
    debug: import.meta.env.VITE_APP_GTM_DEBUG === "true",
    enabled: true,
  })
);

app.mount("#main-app");
