import { ref } from "vue";

const inHomeFitnessValues = {
  websiteAppName: ref(""),
  primaryUrl: ref(""),
  launchDate: ref(""),
  zipcode: ref(""),
  legalEntityName: ref(""),
  legalEntityAddress: ref(""),
  addressLine2: ref(""),
  city: ref(""),
  state: ref(""),
  similarBillingAddress: ref(""),
  billingStreetAddress: ref(""),
  billingCity: ref(""),
  billingState: ref(""),
  billingZipcode: ref(""),
  distributionDesignatedPeriods: ref([]),
  eBillingEmail: ref(""),
};

export { inHomeFitnessValues };
