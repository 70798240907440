import { ref } from "vue";

const userValues = {
  firstName: ref(""),
  lastName: ref(""),
  title: ref(""),
  email: ref(""),
  phoneNumber: ref(""),
};

export { userValues };
