<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { requiredValidation } from "~/utils/validations";
import { fillPeriods } from "@/utils/common";

import useMainStore from "~/apps/stores/main";
import useFormStore from "~/apps/stores/form";

import { webAppValues } from "~/apps/stores/form/web-app";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormTip from "~/apps/components/Form/FormTip.vue";
import FormRadio from "~/apps/components/Form/FormRadio.vue";
import FormCheckbox from "~/apps/components/Form/FormCheckbox.vue";
import FormDatepicker from "~/apps/components/Form/FormDatepicker.vue";
import FormPeriodsInfo from "~/apps/components/Form/FormPeriodsInfo.vue";
import FormPeriodsAth from "~/apps/components/Form/FormPeriodsAth.vue";
import FormPeriodsDistribution from "~/apps/components/Form/FormPeriodsDistribution.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const yesNoOptions = ["Yes", "No"];
const audioVisualOptions = ["Audio only", "Audio visual", "Both"];

const isNonProfitTaxExempt = computed(() => {
  return webAppValues.nonProfitTaxExempt.value.length > 0 && webAppValues.nonProfitTaxExempt.value === "Yes";
});
const isLessRevenue = computed(() => {
  return webAppValues.lessRevenue.value.length > 0 && webAppValues.lessRevenue.value === "Yes";
});
const isThirdParty = computed(() => {
  return webAppValues.thirdParty.value.length > 0 && webAppValues.thirdParty.value === "Yes";
});
const haveSubscription = computed(() => {
  return webAppValues.haveSubscription.value.length > 0 && webAppValues.haveSubscription.value === "Yes";
});
const haveNoSubscription = computed(() => {
  return webAppValues.haveSubscription.value.length > 0 && webAppValues.haveSubscription.value === "No";
});
const isAudioOnly = computed(() => {
  return (
    webAppValues.contentType.value.length > 0 &&
    (webAppValues.contentType.value === "Audio only" || webAppValues.contentType.value === "Both")
  );
});
const isMusicEntertainmentSports = computed(() => {
  return (
    webAppValues.musicEntertainmentSports.value.length > 0 && webAppValues.musicEntertainmentSports.value === "Yes"
  );
});

const isLessRevenueVisible = computed(() => {
  return webAppValues.nonProfitTaxExempt.value.length > 0 && webAppValues.nonProfitTaxExempt.value === "No";
});
const isThirdPartyVisible = computed(() => {
  return (
    isLessRevenueVisible.value && webAppValues.lessRevenue.value.length > 0 && webAppValues.lessRevenue.value === "No"
  );
});
const isHaveSubscriptionVisible = computed(() => {
  return (
    isThirdPartyVisible.value && webAppValues.thirdParty.value.length > 0 && webAppValues.thirdParty.value === "No"
  );
});
const isContentTypeVisible = computed(() => {
  return isHaveSubscriptionVisible.value && haveSubscription.value;
});
const isMusicEntertainmentSportsVisible = computed(() => {
  return (
    isContentTypeVisible.value &&
    webAppValues.contentType.value.length > 0 &&
    webAppValues.contentType.value === "Audio visual"
  );
});

const isAuthorizedPlatformsVisible = computed(() => {
  return isNonProfitTaxExempt.value || isThirdParty.value || haveNoSubscription.value;
});
const isDistributedContentVisible = computed(() => {
  return isMusicEntertainmentSports.value;
});
const isLaunchDateVisible = computed(() => {
  return (
    isNonProfitTaxExempt.value ||
    isLessRevenue.value ||
    isThirdParty.value ||
    haveNoSubscription.value ||
    isAudioOnly.value ||
    isMusicEntertainmentSports.value
  );
});
const isAthPeriodsVisible = computed(() => {
  return isNonProfitTaxExempt.value || isThirdParty.value || haveNoSubscription.value;
});
const isDistributionPeriodsVisible = computed(() => {
  return isAudioOnly.value || isMusicEntertainmentSports.value;
});

const rules = computed(() => ({
  nonProfitTaxExempt: {
    required: requiredValidation(
      "",
      "Is your website or app operated with the intent of furthering a non-profit tax-exempt: (i) religious, (ii ) charitable, or (iii) educational purpose?"
    ),
  },
  lessRevenue: isLessRevenueVisible.value
    ? {
        required: requiredValidation(
          "",
          "Do you anticipate that your website or app will generate less than $134,000 in revenue and less than 288,000 aggregate Tuning Hours (defined below) during a calendar year?"
        ),
      }
    : {},
  thirdParty: isThirdPartyVisible.value
    ? {
        required: requiredValidation(
          "",
          "Do you transmit content via third-party websites or apps (for example via embedded players, pop-out players, framing, in-line linking or any similar technology)?"
        ),
      }
    : {},
  haveSubscription: isHaveSubscriptionVisible.value
    ? {
        required: requiredValidation(
          "",
          "Is your website or app monetized by subscription or transactional payments or through the sale of advertising space to third parties?"
        ),
      }
    : {},
  contentType: isContentTypeVisible.value
    ? { required: requiredValidation("", "Is the content you transmit audio-only, audio visual, or both?") }
    : {},
  musicEntertainmentSports: isMusicEntertainmentSportsVisible.value
    ? {
        required: requiredValidation(
          "",
          "Is the content you transmit primarily music, general entertainment or news talk sports?"
        ),
      }
    : {},
  launchDate: isLaunchDateVisible.value
    ? {
        required: requiredValidation("Launch Date"),
      }
    : {},
}));

const v$ = useVuelidate(rules, webAppValues);
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

watch(webAppValues.distributedContent.generalEntertainment, (value) => {
  if (value) {
    webAppValues.distributedContent.musicIntensive.value = false;
    webAppValues.distributedContent.newsTalkSports.value = false;
  }
});

watch(webAppValues.distributedContent.musicIntensive, (value) => {
  if (value) {
    webAppValues.distributedContent.generalEntertainment.value = false;
    webAppValues.distributedContent.newsTalkSports.value = false;
  }
});

watch(webAppValues.distributedContent.newsTalkSports, (value) => {
  if (value) {
    webAppValues.distributedContent.generalEntertainment.value = false;
    webAppValues.distributedContent.musicIntensive.value = false;
  }
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourWebApp");

  if (webAppValues.distributionDesignatedPeriods.value.length === 0) {
    fillPeriods(webAppValues.launchDate.value, webAppValues.distributionDesignatedPeriods.value);
  }

  if (webAppValues.athDesignatedPeriods.value.length === 0) {
    fillPeriods(webAppValues.launchDate.value, null, webAppValues.athDesignatedPeriods.value);
  }
});

watch(webAppValues.launchDate, (value) => {
  webAppValues.distributionDesignatedPeriods.value = [];
  webAppValues.athDesignatedPeriods.value = [];

  fillPeriods(value, webAppValues.distributionDesignatedPeriods.value, webAppValues.athDesignatedPeriods.value);
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem class="sm:!col-span-12">
      <template #label
        >Is your website or app operated with the intent of furthering a non-profit tax-exempt: (i) religious, (ii )
        charitable, or (iii) educational purpose?</template
      >
      <template #field>
        <FormRadio v-model="webAppValues.nonProfitTaxExempt.value" :attributes="{ options: yesNoOptions }"></FormRadio>
      </template>
    </FormItem>
    <FormItem v-if="isLessRevenueVisible" class="sm:!col-span-12">
      <template #label>
        Do you anticipate that your website or app will generate less than $134,000 in revenue and less than 288,000
        aggregate Tuning Hours (defined below) during a calendar year?
      </template>
      <template #tip>
        <FormTip>
          <p>
            a) "Aggregate Tuning Hours" ("ATH") means the total number of hours of content that is Streamed via the
            Licensed Service during a specified period. By way of example, if LICENSEE Streams one (1) hour of content
            to ten (10) end users via the Licensed Service, LICENSEE's ATH would equal ten (10) hours. Alternatively, if
            LICENSEE Streams ten (10) hours of content to one (1) end user via the Licensed Service, LICENSEE's ATH
            would likewise equal ten (10) hours.
          </p>
        </FormTip>
      </template>
      <template #field>
        <FormRadio v-model="webAppValues.lessRevenue.value" :attributes="{ options: yesNoOptions }"></FormRadio>
      </template>
    </FormItem>
    <p v-if="isLessRevenueVisible" class="text-sm sm:!col-span-12">
      *Please note that you may be eligible for other licenses and that the Limited License contains a slightly higher
      license fee but eliminates all reporting requirements.
    </p>
    <FormItem v-if="isThirdPartyVisible" class="sm:!col-span-12">
      <template #label
        >Do you transmit content via third-party websites or apps (for example via embedded players, pop-out players,
        framing, in-line linking or any similar technology)?</template
      >
      <template #field>
        <FormRadio v-model="webAppValues.thirdParty.value" :attributes="{ options: yesNoOptions }"></FormRadio>
      </template>
    </FormItem>
    <FormItem v-if="isHaveSubscriptionVisible" class="sm:!col-span-12">
      <template #label
        >Is your website or app monetized by subscription or transactional payments or through the sale of advertising
        space to third parties?</template
      >
      <template #field>
        <FormRadio v-model="webAppValues.haveSubscription.value" :attributes="{ options: yesNoOptions }"></FormRadio>
      </template>
    </FormItem>
    <FormItem v-if="isContentTypeVisible" class="sm:!col-span-12">
      <template #label>Is the content you transmit audio-only, audio visual, or both?</template>
      <template #field>
        <FormRadio v-model="webAppValues.contentType.value" :attributes="{ options: audioVisualOptions }"></FormRadio>
      </template>
    </FormItem>
    <FormItem v-if="isMusicEntertainmentSportsVisible" class="sm:!col-span-12">
      <template #label
        >Is the content you transmit primarily music, general entertainment or news talk sports?</template
      >
      <template #field>
        <FormRadio
          v-model="webAppValues.musicEntertainmentSports.value"
          :attributes="{ options: yesNoOptions }"
        ></FormRadio>
      </template>
    </FormItem>
    <FormItem v-if="isAuthorizedPlatformsVisible" class="sm:!col-span-12">
      <template #label>In the fields below, please list any authorized platforms (schedule A)</template>
      <template #tip>
        <FormTip>
          <p>For the purposes of this Agreement only, the following terms shall have the following meaning:</p>
          <p>
            Authorized Third-Party Platform* means a third-party Web Site and/or App that is: (i) listed by brand name
            on Schedule "A" and (ii) operated by a non-profit tax-exempt entity with which LICENSEE's Streaming of audio
            or audiovisual content via such third-party Web Site or App.
          </p>
          <p>
            a) "App" means a software application that is downloadable to, or enabled on, a consumer electronics device
            (e.g., wireless telephone, tablet computer, smart speaker).
          </p>
        </FormTip>
      </template>
      <template #field>
        <FormInput
          v-model="webAppValues.authorizedPlatforms.field1.value"
          class="mb-3"
          type="text"
          placeholder="Field 1"
          full-width
        ></FormInput>
        <FormInput
          v-model="webAppValues.authorizedPlatforms.field2.value"
          class="mb-3"
          type="text"
          placeholder="Field 2"
          full-width
        ></FormInput>
        <FormInput
          v-model="webAppValues.authorizedPlatforms.field3.value"
          class="mb-3"
          type="text"
          placeholder="Field 3"
          full-width
        ></FormInput>
        <FormInput
          v-model="webAppValues.authorizedPlatforms.field4.value"
          class="mb-3"
          type="text"
          placeholder="Field 4"
          full-width
        ></FormInput>
        <FormInput
          v-model="webAppValues.authorizedPlatforms.field5.value"
          type="text"
          placeholder="Field 5"
          full-width
        ></FormInput>
      </template>
    </FormItem>
    <FormItem v-if="isDistributedContentVisible" class="sm:!col-span-12">
      <template #label>What type of content do you distribute?</template>
      <template #field>
        <FormCheckbox v-model="webAppValues.distributedContent.musicIntensive.value" class="mt-3 mb-2"
          >Music Intensive</FormCheckbox
        >
        <FormCheckbox v-model="webAppValues.distributedContent.generalEntertainment.value" class="mb-2"
          >General Entertainment</FormCheckbox
        >
        <FormCheckbox v-model="webAppValues.distributedContent.newsTalkSports.value" class="mb-2"
          >News/Talk/Sports</FormCheckbox
        >
      </template>
    </FormItem>
    <FormItem v-if="isLaunchDateVisible">
      <template #label>What is the launch date of your digital service?</template>
      <template #field>
        <FormDatepicker v-model="webAppValues.launchDate.value" month-picker></FormDatepicker>
      </template>
    </FormItem>
    <div v-if="isAthPeriodsVisible" class="sm:col-span-12">
      <div class="mb-4 flex justify-between">
        <p class="text-[#494949]">
          The calculation of the license fees will be based on the ATH during the applicable Designated Period
          identified in the chart below.
        </p>
        <FormTip>
          <p>For the purposes of this Agreement only, the following terms shall have the following meaning:</p>
          <p>
            "Aggregate Tuning Hours" ("ATH") means the total number of hours of content that is Streamed via the
            Licensed Service during a specified period. By way of example, if LICENSEE Streams one (1) hour of content
            to ten (10) end users via the Licensed Service, LICENSEE's ATH would equal ten (10) hours. Alternatively, if
            LICENSEE Streams ten (10) hours of content to one (1) end user via the Licensed Service, LICENSEE's ATH
            would likewise equal ten (10) hours.
          </p>
        </FormTip>
      </div>
      <FormPeriodsInfo />
      <FormPeriodsAth />
    </div>
    <div v-if="isDistributionPeriodsVisible" class="sm:col-span-12">
      <div class="mb-4">
        <p class="text-[#494949]">
          To calculate license fees, please provide the Distribution, Advertising and other Revenue during the
          applicable Designated Period identified in the chart below.
        </p>
      </div>
      <FormPeriodsInfo />
      <FormPeriodsDistribution />
    </div>
  </FormWrapper>
</template>
