<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { requiredValidation, urlValidation } from "~/utils/validations";
import { fillPeriods } from "@/utils/common";

import useMainStore from "~/apps/stores/main";
import useFormStore from "~/apps/stores/form";

import { inHomeFitnessValues } from "~/apps/stores/form/in-home-fitness";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormPeriodsInfo from "~/apps/components/Form/FormPeriodsInfo.vue";
import FormPeriodsDistribution from "~/apps/components/Form/FormPeriodsDistribution.vue";
import FormDatepicker from "~/apps/components/Form/FormDatepicker.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const rules = computed(() => ({
  websiteAppName: {
    required: requiredValidation("Website/App Name"),
  },
  primaryUrl: {
    ...urlValidation,
  },
  launchDate: {
    required: requiredValidation("Launch Date"),
  },
}));

const v$ = useVuelidate(rules, inHomeFitnessValues);
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourWebApp");

  if (inHomeFitnessValues.distributionDesignatedPeriods.value.length !== 0) return;

  fillPeriods(inHomeFitnessValues.launchDate.value, inHomeFitnessValues.distributionDesignatedPeriods.value);
});

watch(inHomeFitnessValues.launchDate, (value) => {
  inHomeFitnessValues.distributionDesignatedPeriods.value = [];

  fillPeriods(value, inHomeFitnessValues.distributionDesignatedPeriods.value);
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem :capitalize="false" class="sm:!col-span-12">
      <template #label>Name of Service Mark (Website or App Name)*</template>
      <template #field>
        <FormInput v-model="inHomeFitnessValues.websiteAppName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Primary URL (if applicable)</template>
      <template #field>
        <FormInput v-model="inHomeFitnessValues.primaryUrl.value" type="text" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>What was the launch date of your service?</template>
      <template #field>
        <FormDatepicker v-model="inHomeFitnessValues.launchDate.value" month-picker></FormDatepicker>
      </template>
    </FormItem>
    <div class="sm:col-span-12">
      <div class="mb-4">
        <p class="text-[#494949]">
          To calculate license fees, please provide the Distribution, Advertising and other Revenue during the
          applicable Designated Period identified in the chart below.
        </p>
      </div>
      <FormPeriodsInfo />
      <FormPeriodsDistribution flow="In-Home Fitness" />
    </div>
  </FormWrapper>
</template>
