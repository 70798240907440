<script setup>
import { formatDate, numericOnly } from "@/utils/common";

import { webAppValues } from "../../stores/form/web-app";
import { inHomeFitnessValues } from "~/apps/stores/form/in-home-fitness";

import FormInput from "./FormInput.vue";

const props = defineProps({
  flow: {
    type: String,
    default: "Web App",
  },
});

const values = props.flow === "Web App" ? webAppValues : inHomeFitnessValues;
</script>

<template>
  <div class="mt-4">
    <div
      class="hidden grid-cols-4 gap-[1px] bg-[#494949]/30 px-[1px] pt-[1px] text-center text-sm text-[#494949] md:grid"
    >
      <p class="bg-white p-3.5 font-bold">License Fee Period</p>
      <p class="bg-white p-3.5 font-bold">Designated Period</p>
      <p class="col-span-2 bg-white p-3.5 font-bold">Please Provide ATH for Designated Period</p>
    </div>
    <div
      v-for="(period, index) in values.athDesignatedPeriods.value"
      :key="index"
      class="mb-4 grid gap-[1px] bg-[#494949]/30 p-[1px] text-center text-sm text-[#494949] md:mb-0 md:grid-cols-4 md:pb-0 md:pt-[1px] md:last:pb-[1px]"
    >
      <p class="bg-white p-3.5 before:block before:content-['License_Fee_Period:'] md:before:content-none">
        {{ formatDate(period.feePeriodStart) }} - {{ formatDate(period.feePeriodEnd) }}
      </p>
      <p class="bg-white p-3.5 before:block before:content-['Designated_Period:'] md:before:content-none">
        {{ formatDate(period.periodStart) }} - {{ formatDate(period.periodEnd) }}
      </p>
      <p
        class="bg-white p-3.5 before:block before:content-['Please_Provide_ATH_for_Designated_Period:'] md:col-span-2 md:p-0 md:before:content-none"
      >
        <FormInput
          v-model="period.ath"
          class="bg-white text-center"
          kind="number"
          type="text"
          full-width
          placeholder="0"
          @keydown="numericOnly"
        />
      </p>
    </div>
  </div>
</template>
