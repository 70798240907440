<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  bankRoutingNumber,
  bankAccountNumber,
  firstNameValidation,
  lastNameValidation,
  requiredValidation,
  zipcodeValidation,
} from "~/utils/validations";
import { stateAbbreviationsArray } from "@/utils/common";

import useFormStore from "~/apps/stores/form";
import usePaymentStore from "~/apps/stores/payment";

import { achValues } from "~/apps/stores/form/ach";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormSelect from "~/apps/components/Form/FormSelect.vue";
import FormRadio from "~/apps/components/Form/FormRadio.vue";
import FormErrors from "~/apps/components/Form/FormErrors.vue";

const formStore = useFormStore();
const paymentStore = usePaymentStore();

const namingRules = computed(() =>
  achValues.ownershipType.value == "Personal"
    ? {
        firstName: {
          ...firstNameValidation,
        },
        lastName: {
          ...lastNameValidation,
        },
      }
    : {
        businessName: {
          required: requiredValidation("Business Name"),
        },
      }
);

const rules = computed(() => ({
  routingNumber: bankRoutingNumber("Routing Number"),
  accountNumber: bankAccountNumber("Account Number"),
  streetAddress: {
    required: requiredValidation("Street Address"),
  },
  city: {
    required: requiredValidation("City"),
  },
  state: {
    required: requiredValidation("State"),
  },
  zipcode: {
    ...zipcodeValidation,
  },
  ...namingRules.value,
}));

const v$ = useVuelidate(rules, { ...achValues }, { $autoDirty: true });

watch(v$, async (values) => {
  const valid = await v$.value.$validate();
  paymentStore.isValid = valid;
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

const accountTypes = ["Checking", "Savings"];
const ownershipTypes = ["Business", "Personal"];

onMounted(async () => {
  await paymentStore.createAchFields();
});
</script>

<template>
  <FormWrapper id="credit-card-fields" class="relative mt-3 mb-2 border-t pt-3" name="creditCardForm">
    <FormItem class="col-span-12 mb-4">
      <template #label>Bank Account Type*</template>
      <template #field>
        <FormRadio v-model="achValues.accountType.value" :attributes="{ options: accountTypes }"></FormRadio>
      </template>
    </FormItem>

    <FormItem class="col-span-12 mb-4">
      <template #label>Account Ownsership Type*</template>
      <template #field>
        <FormRadio v-model="achValues.ownershipType.value" :attributes="{ options: ownershipTypes }"></FormRadio>
      </template>
    </FormItem>

    <div v-if="achValues.ownershipType.value == 'Business'" class="col-span-12">
      <FormItem>
        <template #label>Business Name*</template>
        <template #field>
          <FormInput v-model="achValues.businessName.value" type="text" required />
        </template>
      </FormItem>
    </div>

    <FormItem v-if="achValues.ownershipType.value == 'Personal'">
      <template #label>First Name*</template>
      <template #field>
        <FormInput v-model="achValues.firstName.value" type="text" required />
      </template>
    </FormItem>

    <FormItem v-if="achValues.ownershipType.value == 'Personal'">
      <template #label>Last Name*</template>
      <template #field>
        <FormInput v-model="achValues.lastName.value" type="text" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>Routing Number*</template>
      <template #field>
        <FormInput v-model="achValues.routingNumber.value" type="text" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>Account Number*</template>
      <template #field>
        <FormInput v-model="achValues.accountNumber.value" type="text" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>Street Address*</template>
      <template #field>
        <FormInput v-model="achValues.streetAddress.value" type="text" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>City*</template>
      <template #field>
        <FormInput v-model="achValues.city.value" type="text" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>State*</template>
      <template #field>
        <FormSelect v-model="achValues.state.value" :options="stateAbbreviationsArray" required />
      </template>
    </FormItem>

    <FormItem>
      <template #label>ZIP*</template>
      <template #field>
        <FormInput v-model="achValues.zipcode.value" type="text" kind="zipcode" required />
      </template>
    </FormItem>

    <FormErrors class="col-span-12" />
  </FormWrapper>
</template>
