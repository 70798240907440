import { ref } from "vue";

const achValues = {
  accountType: ref("Checking"),
  ownershipType: ref("Personal"),
  businessName: ref(""),
  firstName: ref(""),
  lastName: ref(""),
  routingNumber: ref(""),
  accountNumber: ref(""),
  streetAddress: ref(""),
  city: ref(""),
  state: ref(""),
  zipcode: ref(""),
};

export { achValues };
