<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, numeric } from "@vuelidate/validators";
import { requiredValidation, zipcodeValidation } from "@/utils/validations";
import { getState, numericOnly, stateAbbreviationsArray } from "@/utils/common";

import useMainStore from "@/apps/stores/main";
import useFormStore from "@/apps/stores/form";

import { residentialFacilityValues } from "@/apps/stores/form/residential-facility";

import FormWrapper from "@/apps/components/Form/FormWrapper.vue";
import FormItem from "@/apps/components/Form/FormItem.vue";
import FormInput from "@/apps/components/Form/FormInput.vue";
import FormSelect from "@/apps/components/Form/FormSelect.vue";
import FormDatepicker from "@/apps/components/Form/FormDatepicker.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const similarBillingAddressOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];
const isSimilarBillingAddress = computed(() => {
  return (
    residentialFacilityValues.similarBillingAddress.value.length === 0 ||
    residentialFacilityValues.similarBillingAddress.value === "Yes"
  );
});

const billingRules = computed(() =>
  !isSimilarBillingAddress.value
    ? {
        billingStreetAddress: {
          required: requiredValidation("Billing Street Address"),
        },
        billingCity: {
          required: requiredValidation("Billing City"),
        },
        billingState: {
          required: requiredValidation("Billing State"),
        },
        billingZipcode: {
          ...zipcodeValidation("Billing Zip Code"),
        },
      }
    : {}
);

const rules = computed(() => ({
  residentialFacilityName: {
    required: requiredValidation("Retail Store Name"),
  },
  streetAddress: {
    required: requiredValidation("Street Address"),
  },
  city: {
    required: requiredValidation("City"),
  },
  state: {
    required: requiredValidation("State"),
  },
  zipcode: {
    ...zipcodeValidation("Residential Facility Zip Code"),
  },
  similarBillingAddress: {
    required: requiredValidation("", "Is Your Billing Address The Same As Your Establishment?"),
  },
  ...billingRules.value,
  legalEntityName: {
    required: requiredValidation("Legal Entity Name"),
  },
  residentialFacilityMusicStartDate: {
    required: requiredValidation("Music Start Date"),
  },
  residentialFacilityUnitsNumber: {
    required: requiredValidation("Number of Units"),
    numeric: helpers.withMessage("Number of Units can only contain digits.", numeric),
  },
}));

const v$ = useVuelidate(rules, residentialFacilityValues);
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourEstablishment");

  if (!residentialFacilityValues.state.value) {
    residentialFacilityValues.state.value = getState(residentialFacilityValues.zipcode.value);
  }
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem class="sm:col-span-12">
      <template #label>Residential Facility Name*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.residentialFacilityName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Street Address*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.streetAddress.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Address Line 2</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.addressLine2.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>City*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.city.value" type="text" required />
      </template>
    </FormItem>
    <FormItem class="sm:col-span-2">
      <template #label>State*</template>
      <template #field>
        <FormSelect
          v-model="residentialFacilityValues.state.value"
          :options="[{ label: 'State', value: '' }, ...stateAbbreviationsArray]"
        />
      </template>
    </FormItem>
    <FormItem class="sm:col-span-4">
      <template #label>Residential Facility Zip Code*</template>
      <template #field>
        <FormInput
          v-model="residentialFacilityValues.zipcode.value"
          type="text"
          kind="zipcode"
          required
          pattern="[0-9]*"
        />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Is Your Billing Address The Same As Your Establishment?</template>
      <template #field>
        <FormSelect
          v-model="residentialFacilityValues.similarBillingAddress.value"
          :options="similarBillingAddressOptions"
        />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-12">
      <template #label>Billing Street Address*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.billingStreetAddress.value" full-width type="text" required />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress">
      <template #label>Billing City*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.billingCity.value" type="text" required />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-2">
      <template #label>Billing State*</template>
      <template #field>
        <FormSelect
          v-model="residentialFacilityValues.billingState.value"
          :options="[{ label: 'State', value: '' }, ...stateAbbreviationsArray]"
        />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-4">
      <template #label>Billing Zip Code*</template>
      <template #field>
        <FormInput
          v-model="residentialFacilityValues.billingZipcode.value"
          type="text"
          kind="zipcode"
          required
          pattern="[0-9]*"
        />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Legal entity name*</template>
      <template #field>
        <FormInput v-model="residentialFacilityValues.legalEntityName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>When did your Residential Facility start using music?*</template>
      <template #field>
        <FormDatepicker
          v-model="residentialFacilityValues.residentialFacilityMusicStartDate.value"
          required
          month-picker
        />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Please enter the total number of units in your residential facility*</template>
      <template #field>
        <FormInput
          v-model="residentialFacilityValues.residentialFacilityUnitsNumber.value"
          type="number"
          required
          @keydown="numericOnly"
        />
      </template>
    </FormItem>
  </FormWrapper>
</template>
