<script setup>
import { computed, ref } from "vue";
import { onClickOutside, onKeyStroke } from "@vueuse/core";

const props = defineProps({
  fullWidth: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const selectRef = ref(null);
const isDropdownOpen = ref(false);

const currentFocusedOption = ref(-1);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;

  currentFocusedOption.value = -1;
};

onClickOutside(selectRef, () => {
  isDropdownOpen.value = false;
});

onKeyStroke("ArrowUp", (event) => {
  if (isDropdownOpen.value) {
    event.preventDefault();

    currentFocusedOption.value = currentFocusedOption.value - 1;

    if (currentFocusedOption.value < 0) {
      currentFocusedOption.value = buttonRefs.value.length - 1;
    }

    buttonRefs.value[currentFocusedOption.value].focus();
  }
});

onKeyStroke("ArrowDown", (event) => {
  if (isDropdownOpen.value) {
    event.preventDefault();

    currentFocusedOption.value = currentFocusedOption.value + 1;

    if (currentFocusedOption.value > buttonRefs.value.length - 1) {
      currentFocusedOption.value = 0;
    }

    buttonRefs.value[currentFocusedOption.value].focus();
  }
});

onKeyStroke("Tab", (event) => {
  if (!isDropdownOpen.value) return;

  event.preventDefault();

  if (event.shiftKey) {
    currentFocusedOption.value = currentFocusedOption.value - 1;

    if (currentFocusedOption.value < 0) {
      currentFocusedOption.value = buttonRefs.value.length - 1;
    }

    buttonRefs.value[currentFocusedOption.value].focus();
  } else {
    currentFocusedOption.value = currentFocusedOption.value + 1;

    if (currentFocusedOption.value > buttonRefs.value.length - 1) {
      currentFocusedOption.value = 0;
    }

    buttonRefs.value[currentFocusedOption.value].focus();
  }
});

onKeyStroke("Escape", (event) => {
  if (isDropdownOpen.value) {
    event.preventDefault();

    isDropdownOpen.value = false;
  }
});

const buttonRefs = ref([]);

const modelValueText = computed(() => (props.modelValue ? props.modelValue : props.placeholder));
const modelValueOptions = computed(() => props.modelValue.split(", "));

const selectedOptions = ref([]);
const updateOptions = (value) => {
  if (!selectedOptions.value.includes(value)) {
    selectedOptions.value.push(value);
  } else {
    selectedOptions.value = selectedOptions.value.filter((option) => option !== value);
  }

  emit("update:modelValue", selectedOptions.value.join(", "));
};
</script>

<template>
  <div ref="selectRef" class="group relative block" :class="{ 'sm:max-w-[460px]': !fullWidth }">
    <button
      class="select min-h-[48px] w-full rounded-none border-none bg-[#F5F5F5] py-3 pl-5 text-left ring-[1px] ring-transparent focus:outline-none focus:ring-[#d43da5] group-focus-within:ring-[#d43da5]"
      type="button"
      @click="toggleDropdown"
    >
      {{ modelValueText }}
    </button>
    <div
      v-show="isDropdownOpen"
      class="absolute top-[calc(100%+10px)] left-0 z-10 grid h-[200px] w-full gap-1 overflow-y-auto bg-[#F5F5F5] p-4"
    >
      <button
        v-for="option in options"
        ref="buttonRefs"
        :key="option.value"
        class="rounded-none border-none px-4 py-2 text-left text-sm ring-[1px] ring-transparent focus:outline-none focus:ring-[#d43da5]"
        :class="{ 'bg-white': modelValueOptions.includes(option.value) }"
        type="button"
        @click="updateOptions(option.value)"
      >
        {{ option.label ? option.label : option.value }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.select {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='8px' viewBox='0 0 11 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03_GetALicense' transform='translate(-834.000000, -513.000000)' fill='%23000000'%3E%3Cpolygon id='Triangle' transform='translate(839.500000, 517.000000) rotate(-180.000000) translate(-839.500000, -517.000000) ' points='839.5 513 845 521 834 521'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 11px 8px;
  background-position: right 1.5rem center;
  background-repeat: no-repeat;
}
</style>
