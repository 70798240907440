<script setup>
import { onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
  requiredValidation,
  titleValidation,
  zipcodeValidation,
} from "~/utils/validations";

import useMainStore from "~/apps/stores/main";
import useFormStore from "~/apps/stores/form";

import { userValues } from "~/apps/stores/form/user";
import { specialEventsValues } from "~/apps/stores/form/special-events";

import FormWrapper from "~/apps/components/Form/FormWrapper.vue";
import FormItem from "~/apps/components/Form/FormItem.vue";
import FormInput from "~/apps/components/Form/FormInput.vue";
import FormDatepicker from "~/apps/components/Form/FormDatepicker.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const rules = {
  eventName: {
    required: requiredValidation("Name of Festival/Event"),
  },
  zipcode: {
    ...zipcodeValidation("Festival/Event Zip Code"),
  },
  eventDate: {
    required: requiredValidation("Event Date"),
  },
  firstName: {
    ...firstNameValidation,
  },
  lastName: {
    ...lastNameValidation,
  },
  title: {
    ...titleValidation,
  },
  email: {
    ...emailValidation,
  },
  phoneNumber: {
    ...phoneValidation,
  },
};

const v$ = useVuelidate(rules, { ...userValues, ...specialEventsValues });
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourself");
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem class="sm:col-span-12">
      <template #label>Name of Festival/Event*</template>
      <template #field>
        <FormInput v-model="specialEventsValues.eventName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Festival/Event Zip Code*</template>
      <template #field>
        <FormInput v-model="specialEventsValues.zipcode.value" type="text" kind="zipcode" required pattern="[0-9]*" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Date of Festival/Event*</template>
      <template #field>
        <FormDatepicker v-model="specialEventsValues.eventDate.value" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>First Name*</template>
      <template #field>
        <FormInput v-model="userValues.firstName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Last Name*</template>
      <template #field>
        <FormInput v-model="userValues.lastName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Title*</template>
      <template #field>
        <FormInput v-model="userValues.title.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Email*</template>
      <template #field>
        <FormInput v-model="userValues.email.value" type="email" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Your Phone Number*</template>
      <template #field>
        <FormInput v-model="userValues.phoneNumber.value" type="text" kind="phone" required />
      </template>
    </FormItem>
  </FormWrapper>
</template>
