import { useGtm } from "@gtm-support/vue-gtm";
import { defineStore } from "pinia";

const useMainStore = defineStore("main", {
  state: () => ({
    currentView: "LicenseTypeView",
    subheading: "Select Your Business Type",
    description: "",
    currentStep: 1,
    stepCount: 4,
  }),
  actions: {
    setCurrentView(view) {
      this.currentView = view;

      this.setSubheading(view);
    },
    setSubheading(type) {
      const types = {
        LicenseTypeView: "Select Your Business Type",
        AnnualLicenseView: "Review and Purchase Your Annual License",
        PaymentView: "Choose a Payment Method",
        TellUsAboutYourself: "Tell Us About Yourself",
        TellUsAboutYourEstablishment: "Tell Us About Your Establishment",
        TellUsAboutYourWebApp: "Tell Us About Your Website or App",
        MusicInYourEstablishment: "Music In Your Establishment",
        ThankYouView: "Thank You",
        PaymentSuccessfulView: "Thank you! Your payment is being processed.",
        LicenseUncompletedView: "Sorry. Something went wrong while we were processing your license.",
      };

      this.subheading = types[type];
    },
    setDescription(description = "") {
      this.description = description;
    },
    setStepCount(count) {
      this.stepCount = count;
    },
    setCurrentStep(step) {
      this.currentStep = step;
    },
    previousStep() {
      if (this.currentStep > 2) {
        this.setCurrentStep(this.currentStep - 1);

        return;
      }

      this.setCurrentStep(this.currentStep - 1);
      this.setCurrentView("LicenseTypeView");
    },
    nextStep(isContact) {
      this.setCurrentStep(this.currentStep + 1);
      this.gtmNextStep(isContact);
    },
    gtmFirstStep(business_type) {
      const gtm = useGtm();

      gtm.trackEvent({ event: `next_step_1`, business_type });
    },
    gtmNextStep(isContact) {
      const gtm = useGtm();

      const step = {
        2: "second",
        3: "third",
        4: "forth",
        5: "fifth",
      };

      if (isContact) gtm.trackEvent({ event: "generate_lead" });
      else gtm.trackEvent({ event: `gal_${step[this.currentStep]}_page` });
    },
  },
});

export default useMainStore;
