import { defineStore } from "pinia";

import API from "../../../API";
import { stepCount } from "@/utils/variables";
import useMainStore from "../main";

const useFlowsStore = defineStore("flows", {
  state: () => ({
    flows: [],
    currentFlow: {},
  }),
  actions: {
    setCurrentFlow(flow) {
      const mainStore = useMainStore();

      this.currentFlow = flow;
      mainStore.setStepCount(stepCount[flow.name]);
    },
    async getFlows() {
      const { data } = await API.getFlows();
      this.flows = data;
      this.setCurrentFlow(data[0]);
    },
  },
});

export default useFlowsStore;
