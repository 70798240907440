<script setup>
import { computed } from "vue";

const props = defineProps({
  kind: {
    type: String,
    default: "black-border",
    validator: (value) => ["black-border", "red"].includes(value),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const buttonClass = computed(() => {
  const map = {
    "black-border": "text-black border-2 border-solid border-black",
    red: "bg-[#EE3D42] text-white",
  };

  return map[props.kind];
});
</script>

<template>
  <button
    class="inline-block h-[48px] min-w-[120px] rounded-3xl px-8 font-bold focus-visible:outline-[#d43da5]"
    :class="[buttonClass, { 'cursor-not-allowed opacity-40': disabled }]"
    type="button"
  >
    <slot />
  </button>
</template>
