import { defineStore } from "pinia";
import API from "../../../API";

import useUserStore from "../user";

import { achValues } from "../form/ach";

import usBankAccount from "braintree-web/us-bank-account";
import hostedFields from "braintree-web/hosted-fields";
import braintreeClient from "braintree-web/client";
import dataCollector from "braintree-web/data-collector";
import paypalCheckout from "braintree-web/paypal-checkout";

const usePaymentStore = defineStore("payment", {
  state: () => ({
    selectedMethod: "",
    license: null,
    isValid: false,
    autopay: false,
    eBilling: "",
    clientToken: "",
    clientInstance: null,
    deviceData: null,
    paymentInstance: null,
    transactionId: null,
    paymentMethodToken: null,
    nonce: null,
    isApiFree: true,
    status: { tokenize: null, transaction: null, complete: null },
  }),
  getters: {
    userStore() {
      return useUserStore();
    },
  },
  actions: {
    setPaymentMethod(method) {
      this.selectedMethod = method;
      this.isValid = false;
    },
    async getLicenseId() {
      await this.getLicenseIfNull();

      return this.license.licenseId;
    },
    async getLicenseIfNull() {
      if (this.license == null) {
        try {
          const { data } = await API.getLicense(this.userStore.userFlow?.id);
          this.license = data;
        } catch (err) {
          console.error("Can't get LICENSE value");
          throw new Error(err);
        }
      }
    },
    async setClientToken() {
      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          const { data } = await API.getBraintreeToken();

          this.clientToken = data.client_token;
          console.log("-- Got CLIENT-TOKEN");
        }
      } catch (err) {
        console.error("Can't get Braintree Client Token");
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }
    },
    async createClientInstance() {
      if (!this.clientToken) {
        await this.setClientToken();
      }

      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          this.clientInstance = await braintreeClient.create({
            authorization: this.clientToken,
          });

          console.log("-- Got CLIENT-INSTANCE");
        }
      } catch (err) {
        console.error("Can't create client instance.");
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }

      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          const { deviceData } = await dataCollector.create({ client: this.clientInstance });

          this.deviceData = deviceData;
          console.log("-- Got DEVICE-DATA");
        }
      } catch (err) {
        console.error("Can't create dataCollector instance.");
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }

      return this.clientInstance;
    },
    async createPaypalCheckout() {
      if (!this.clientInstance) {
        await this.createClientInstance();
      }

      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          this.paymentInstance = await paypalCheckout.create({
            client: this.clientInstance,
          });

          await this.paymentInstance.loadPayPalSDK({
            currency: "USD",
            intent: "capture",
          });

          await this.getLicenseIfNull();
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }
    },
    async createCreditCardFields(options) {
      if (!this.clientInstance) {
        await this.createClientInstance();
      }

      options.client = this.clientInstance;

      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          this.paymentInstance = await hostedFields.create(options);

          console.log("-- Created CreditCard HOSTED FIELDS");
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }

      this.paymentInstance.on("validityChange", (event) => {
        this.isValid = Object.keys(event.fields).every((key) => {
          return event.fields[key].isValid;
        });
      });
    },
    async createAchFields() {
      if (!this.clientInstance) {
        await this.createClientInstance();
      }

      const options = {
        client: this.clientInstance,
      };

      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          this.paymentInstance = await usBankAccount.create(options);

          console.log("-- Created ACH HOSTED FIELDS");
        }
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }
    },
    // -- CALL API ENDPOINT /transaction
    async postTransaction() {
      const paymentMethod = () => {
        switch (this.selectedMethod) {
          case "Credit Card":
            return "card";
          case "ACH":
            return "bank";
          default:
            return "paypal";
        }
      };

      try {
        if (this.isApiFree) {
          this.isApiFree = false;

          const { data } = await API.postTransaction(
            this.nonce,
            this.deviceData,
            this.license.licenseAnnualFee,
            this.userStore.user.email,
            paymentMethod(),
            this.autopay,
            "recaptcha_token"
          );

          if (data.transaction_id) {
            this.transactionId = data.transaction_id;
            this.paymentMethodToken = data.payment_method_token;
            this.status.transaction = true;
            console.log("-- DONE Transaction API call");
          } else {
            console.error("-- DONE But got no Transaction ID back");
            this.status.transaction = false;
            throw new Error("We couldn't create your transaction");
          }
        }
      } catch (err) {
        this.status.transaction = false;
        console.error("Can't POST transaction data");
        throw new Error("We couldn't submit your payment.");
      } finally {
        this.isApiFree = true;
      }
    },
    // -- CALL API ENDPOINT /license/:licenseID/complete/braintree
    async completeLicense() {
      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          const transactionComplete = API.completeLicense(
            this.license.licenseId,
            this.transactionId,
            this.autopay,
            this.paymentMethodToken,
            this.selectedMethod,
            this.eBilling == "" ? "none" : this.eBilling
          );

          this.status.complete = true;
          console.log("-- DONE License Complete Braintree API call");
        }
      } catch (err) {
        this.status.complete = false;
        console.error("Can't complete license");
        throw new Error(err);
      } finally {
        this.isApiFree = true;
      }
    },
    // This runs only for CreditCard and ACH
    async submitPayment() {
      let options = {};

      if (this.selectedMethod == "ACH") {
        options.mandateText =
          'By clicking "Submit Payment", I authorize Braintree, a service of PayPal, on behalf of SESAC (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.';
        options.bankDetails = {
          accountNumber: achValues.accountNumber.value,
          routingNumber: achValues.routingNumber.value,
          accountType: achValues.accountType.value.toLowerCase(),
          ownershipType: achValues.ownershipType.value.toLowerCase(),
          billingAddress: {
            streetAddress: achValues.streetAddress.value,
            locality: achValues.city.value,
            region: achValues.state.value,
            postalCode: achValues.zipcode.value,
          },
        };

        if (achValues.ownershipType.value == "Personal") {
          options.bankDetails.firstName = achValues.firstName.value;
          options.bankDetails.lastName = achValues.lastName.value;
        } else {
          options.bankDetails.businessName = achValues.businessName.value;
        }
      }

      await this.getLicenseIfNull();

      // -- TOKENIZE PAYMENT INSTANCE
      try {
        if (this.isApiFree) {
          this.isApiFree = false;
          const payload = await this.paymentInstance.tokenize(options);

          this.nonce = payload.nonce;
          this.status.tokenize = true;
          console.log("-- Got Payload NONCE");
        }
      } catch (err) {
        this.status.tokenize = false;
        console.error("Can't get tokenized paymentInstance");
        throw new Error("We couldn't submit your payment.");
      } finally {
        this.isApiFree = true;
      }

      // -- CALL API ENDPOINT /transaction
      await this.postTransaction();

      // -- CALL API ENDPOINT /license/:licenseID/complete/braintree
      await this.completeLicense();
    },
  },
});

export default usePaymentStore;
