<script setup>
import { computed, ref } from "vue";
import { nextStep } from "~/utils/common";

import useMainStore from "~/apps/stores/main";
import useUserStore from "~/apps/stores/user";
import useFlowsStore from "~/apps/stores/flows";

import { countryClubsValues } from "~/apps/stores/form/country-clubs";

import TheButton from "~/apps/components/TheButton.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import FormErrors from "~/apps/components/Form/FormErrors.vue";

import TellUsAboutYourself from "./CountryClubs/TellUsAboutYourself.vue";

const components = {
  1: TellUsAboutYourself,
};

const mainStore = useMainStore();
const userStore = useUserStore();
const flowsStore = useFlowsStore();

const currentStep = computed(() => mainStore.currentStep);
const currentView = computed(() => mainStore.currentView);

const component = ref(null);

const back = () => {
  mainStore.previousStep();
};

const next = async () => {
  const isValid = await component.value.validate();

  if (!isValid) return;

  await nextStep(countryClubsValues, true);
};
</script>

<template>
  <Component :is="components[currentStep - 1]" ref="component" />
  <FormErrors />
  <TheButtonWrapper>
    <TheButton @click="back">Back</TheButton>
    <TheButton kind="red" @click="next">Next Step</TheButton>
  </TheButtonWrapper>
</template>
