<script setup>
import TheButton from "../../../components/TheButton.vue";
import ImageBannerDesktop from "../../../../../javascript/images/SESAC-GAL-Churches-Header-Desktop.jpg";
import ImageBannerMobile from "../../../../../javascript/images/SESAC-GAL-Churches-Header-Mobile.jpg";
import WorshipImage from "../../../../../javascript/images/worship.png";
import PerformImage from "../../../../../javascript/images/perform-music.png";

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};
</script>

<template>
  <div class="mb-6">
    <img :src="ImageBannerDesktop" alt="" class="hidden md:block" />
    <img :src="ImageBannerMobile" alt="" class="block md:hidden" />
  </div>

  <div>
    <p class="mb-6">
      Christian Copyright Solutions (CCS) and SESAC work together to help churches and non-profit religious organizations
      comply with U.S. Copyright Law while supporting the creative work of amazing songwriters.
    </p>
    <p class="mb-6">
      With simple, one-stop annual performance licenses, CCS and SESAC make it easy and cost-effective for churches to 
      ensure that all their ministry's activities are legally covered, both online and onsite.
    </p>
    <p class="mb-6">
      CCS is widely considered the "best in class" for church music rights and offers licensing options that give 
      churches the legal permission to play, perform, and stream over 28 million Christian and secular songs from all 
      genres, including popular holiday and children's songs.
    </p>
    <p class="mb-6">
      Get instant access to comprehensive performance licensing coverage for all your music needs by signing up 
      for licenses today.
    </p>
  </div>

  <div class="mb-12">
    <TheButton
      kind="red"
      role="link"
      @click="openInNewTab('https://apps.christiancopyrightsolutions.com/purchase-license.aspx?svc=wcpm')"
    >
      GET A LICENSE
    </TheButton>
  </div>

  <div class="mb-6 flex flex-col justify-between md:flex-row">
    <div class="mb-6 basis-1/2">
      <img :src="PerformImage" alt="" class="mb-6" />
      <p class="mb-6">
        Enhance all your church activities with music. Legally play or perform millions of Christian and secular songs 
        onsite throughout your facilities and satellite campuses with the PERFORMmusic Facilities License.
      </p>
      <TheButton kind="red" role="link" @click="openInNewTab('https://christiancopyrightsolutions.com/performmusic/')">
        Learn More
      </TheButton>
    </div>
    <div class="mb-6 basis-1/2">
      <img :src="WorshipImage" alt="" class="mb-6" />
      <p class="mb-6">
        You can legally stream your performances of copyrighted music, including worship services and special events, 
        live or on-demand, from your ministry's website with the WORSHIPcast Streaming License.
      </p>
      <TheButton kind="red" role="link" @click="openInNewTab('https://christiancopyrightsolutions.com/worshipcast/')">
        Learn More
      </TheButton>
    </div>
  </div>

  <div class="mb-12">
    <h1>Contact CCS Today</h1>
    For further information, visit at <a href="https://christiancopyrightsolutions.com/">www.christiancopyrightsolutions.com</a> or give us a call at <a href="tel:8555765837">(855) 576-5837</a>.
  </div>
</template>

<style>
  a {
    text-decoration: underline;
    color: red;
  }
</style>
