import { ref } from "vue";

const fitnessCenterValues = {
  locationName: ref(""),
  zipcode: ref(""),
  locationsNumber: ref("1"),
  streetAddress: ref(""),
  addressLine2: ref(""),
  city: ref(""),
  state: ref(""),
  similarBillingAddress: ref(""),
  billingStreetAddress: ref(""),
  billingCity: ref(""),
  billingState: ref(""),
  billingZipcode: ref(""),
  legalEntityName: ref(""),
  businessMusicStartDate: ref(""),
  eBillingEmail: ref(""),
};

export { fitnessCenterValues };
