<script setup>
import { onMounted } from "vue";

import { stepCount } from "@/utils/variables";
import useMainStore from "../stores/main";
import useFlowsStore from "../stores/flows";

import OtherView from "./LicenseTypes/OtherView.vue";
import RetailStoreView from "./LicenseTypes/RetailStoreView.vue";
import RadioView from "./LicenseTypes/RadioView.vue";
import SpecialEventsView from "./LicenseTypes/SpecialEventsView.vue";
import CountryClubsView from "./LicenseTypes/CountryClubsView.vue";
import ResidentialFacilityView from "./LicenseTypes/ResidentialFacilityView.vue";
import FitnessCenterView from "./LicenseTypes/FitnessCenterView.vue";
import HotelsView from "./LicenseTypes/HotelsView.vue";
import RestaurantsView from "./LicenseTypes/RestaurantsView.vue";
import WebAppView from "./LicenseTypes/WebAppView.vue";
import InHomeFitnessView from "./LicenseTypes/InHomeFitnessView.vue";
import ChurchOrMinistryView from "./LicenseTypes/ChurchOrMinistryView.vue";

const flowStore = useFlowsStore();
const mainStore = useMainStore();

const licenseTypeViewMap = {
  Other: OtherView,
  "Retail Store": RetailStoreView,
  Radio: RadioView,
  "Special Events": SpecialEventsView,
  "Country Clubs": CountryClubsView,
  "Residential Facility": ResidentialFacilityView,
  "Fitness Center": FitnessCenterView,
  Hotels: HotelsView,
  Restaurants: RestaurantsView,
  "Web App": WebAppView,
  "In-Home Fitness": InHomeFitnessView,
  "Church Or Ministry": ChurchOrMinistryView,
};

onMounted(() => {
  mainStore.setStepCount(stepCount[flowStore.currentFlow.name]);
});
</script>

<template>
  <Component :is="licenseTypeViewMap[flowStore.currentFlow.name]" />
</template>
