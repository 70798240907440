<script setup>
import { computed } from "vue";
import { useDateFormat } from "@vueuse/core";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { addLeadingZeros } from "@/utils/common";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  monthPicker: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const currentYear = new Date().getUTCFullYear();
const limitDate = new Date(currentYear - 3, 0, 1);
const currentDate = new Date();

const formattedModelValue = computed(() => {
  const date = new Date(props.modelValue);

  if (props.monthPicker) {
    // Return UTC month + year
    return date.getUTCFullYear()
      ? {
          year: date.getUTCFullYear(),
          month: date.getUTCMonth(),
        }
      : {};
  }

  // Set date values as UTC
  date.setFullYear(date.getUTCFullYear());
  date.setMonth(date.getUTCMonth());
  date.setDate(date.getUTCDate());

  return date;
});

const format = computed(() => {
  if (props.monthPicker) {
    return "MM/yyyy";
  }

  return "MM/dd/yyyy";
});

const setDate = (value) => {
  if (!value) return;

  if (props.monthPicker) {
    emit("update:modelValue", `${value.year}-${addLeadingZeros(value.month + 1, 2)}-01`);
    return;
  }

  emit("update:modelValue", useDateFormat(value, "YYYY-MM-DD").value);
};
</script>

<template>
  <Datepicker
    :model-value="formattedModelValue"
    :month-picker="monthPicker"
    arrow-navigation
    auto-apply
    :format="format"
    :enable-time-picker="false"
    :utc="true"
    :clearable="clearable"
    :min-date="limitDate"
    @update:model-value="setDate"
  ></Datepicker>
</template>

<style>
.dp__input {
  min-height: 48px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0;
}

.dp__input:focus {
  border: 1px solid #d43da5;
}
</style>
