<script setup>
import { computed, onMounted, ref, watch } from "vue";

import { useGtm } from "@gtm-support/vue-gtm";

import useMainStore from "../stores/main";
import usePaymentStore from "~/apps/stores/payment";
import useUserStore from "~/apps/stores/user";
import useFlowsStore from "@/apps/stores/flows";

import FormItem from "~/apps/components/Form/FormItem.vue";
import FormRadio from "~/apps/components/Form/FormRadio.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import TheButton from "~/apps/components/TheButton.vue";

import CreditCard from "./PaymentMethods/CreditCard.vue";
import ACH from "./PaymentMethods/ACH.vue";
import Paypal from "./PaymentMethods/PaypalButton.vue";

const components = {
  "Credit Card": CreditCard,
  ACH: ACH,
  Paypal: Paypal,
};

const mainStore = useMainStore();
const paymentStore = usePaymentStore();
const userStore = useUserStore();
const flowStore = useFlowsStore();
const currentStep = computed(() => mainStore.currentStep);

const paymentMethod = ref("null");
const loading = ref(false);

const paymentMethods = ["Paypal", "Credit Card", "ACH"];

const gtm = useGtm();

const isValid = computed(() => {
  return paymentStore.isValid && !loading.value;
});

const pay = async () => {
  if (!isValid.value) return;
  loading.value = true;

  try {
    await paymentStore.submitPayment();

    await success();
  } catch (err) {
    // EITHER TOKENIZE OR TRANSACTION CALL FAILED. REENABLE SUBMIT PAYMENT BUTTON
    if (!paymentStore.status.tokenize || !paymentStore.status.transaction) {
      alert(err);
      loading.value = false;
    }

    // TRANSACTION CALL WAS SUCCESSFULL BUT COMPLETE/BRAINTREE WAS NOT. REDIRECTS USER TO LICENSE UNCOMPLETE PAGE
    else if (paymentStore.status.transaction) {
      await userStore.updateUserFlow(userStore.userFlow.id, currentStep.value, "LicenseUncompletedView", {}, true);
      mainStore.setCurrentView("LicenseUncompletedView");
    }
  }
};

const success = async () => {
  gtm.trackEvent({
    event: "purchase",
    ecommerce: {
      transaction_id: paymentStore.transactionId,
      value: paymentStore.license.licenseFullYearFee,
      currency: "USD",
      items: [
        {
          item_name: flowStore.currentFlow.name,
        },
      ],
    },
  });

  // ALL API CALLS WERE SUCCESSFULL. REDIRECTS USER TO THANK YOU PAGE
  await userStore.updateUserFlow(userStore.userFlow.id, currentStep.value, "PaymentSuccessfulView", {}, true);
  mainStore.setCurrentView("PaymentSuccessfulView");
};

onMounted(async () => {
  paymentMethod.value = paymentStore.selectedMethod;
});

watch(paymentMethod, (newValue) => {
  paymentStore.setPaymentMethod(newValue);
});
</script>

<template>
  <FormItem class="col-span-12">
    <template #field>
      <FormRadio v-model="paymentMethod" :attributes="{ options: paymentMethods }"></FormRadio>
    </template>
  </FormItem>

  <Component :is="components[paymentMethod]" @paypal-complete="success" />

  <TheButtonWrapper v-if="paymentMethod && paymentMethod !== 'Paypal'">
    <TheButton kind="red" :disabled="!isValid" @click="pay">
      <span v-if="!loading">Submit Payment</span>
      <div v-else class="flex items-center justify-center">
        <div
          class="mr-2 h-6 w-6 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-400 ease-linear"
        ></div>
        <span class="text-center text-xl font-semibold text-white">Processing...</span>
      </div>
    </TheButton>
  </TheButtonWrapper>
</template>
