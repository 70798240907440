<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import { onClickOutside, reactiveComputed, useScroll } from "@vueuse/core";
import useVuelidate from "@vuelidate/core";
import { addLeadingZeros, formatCurrency } from "@/utils/common";
import { emailValidation } from "~/utils/validations";

import checkExample from "~/example-check.png";

import API from "~/API";
import useMainStore from "~/apps/stores/main";
import useUserStore from "~/apps/stores/user";
import useFormStore from "~/apps/stores/form";
import usePaymentStore from "~/apps/stores/payment";
import useFlowsStore from "@/apps/stores/flows";

import { restaurantsValues } from "@/apps/stores/form/restaurants";
import { hotelsValues } from "@/apps/stores/form/hotels";
import { residentialFacilityValues } from "@/apps/stores/form/residential-facility";
import { fitnessCenterValues } from "@/apps/stores/form/fitness-center";
import { webAppValues } from "@/apps/stores/form/web-app";
import { inHomeFitnessValues } from "@/apps/stores/form/in-home-fitness";

import FormCheckbox from "~/apps/components/Form/FormCheckbox.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import TheButton from "~/apps/components/TheButton.vue";
import FormInput from "@/apps/components/Form/FormInput.vue";

const mainStore = useMainStore();
const userStore = useUserStore();
const formStore = useFormStore();
const flowStore = useFlowsStore();
const paymentStore = usePaymentStore();

const formValues = {
  Restaurants: restaurantsValues,
  Hotels: hotelsValues,
  "Residential Facility": residentialFacilityValues,
  "Fitness Center": fitnessCenterValues,
  "Web App": webAppValues,
  "In-Home Fitness": inHomeFitnessValues,
};

const loading = ref(false);
const pdfRead = ref(false);
const termsAndConditions = ref(false);
const creditDebitCard = ref(false);
const ach = ref(false);
const paypal = ref(false);
const autopay = ref(true);
const isCheckVisible = ref(false);
const sampleCheck = ref();

const pdf = ref(null);
const { arrivedState } = useScroll(pdf);

const license = ref({});
const formattedLicenseAnnualFee = ref(null);
const formattedLicenseFullYearFee = ref(null);
const formattedLicenseEnd = ref(null);
const formattedLicenseStart = ref(null);

const values = formValues[flowStore.currentFlow.name];

const isRestaurantsFlow = computed(() => flowStore.currentFlow.name === "Restaurants");
const isDigitalFlow = computed(() => /(In-Home Fitness|Web App)/.test(flowStore.currentFlow.name));

const nameTypes = [
  {
    key: "countryClubName",
    label: "Country Club Name",
  },
  {
    key: "locationName",
    label: "Location Name",
  },
  {
    key: "hotelName",
    label: "Hotel Name",
  },
  {
    key: "websiteAppName",
    label: "Website or App Name",
  },
  {
    key: "businessName",
    label: "Business Name",
  },
  {
    key: "websiteAppNameCallLetters",
    label: "Website/App Name/Call Letters",
  },
  {
    key: "residentialFacilityName",
    label: "Residential Facility Name",
  },
  {
    key: "establishmentName",
    label: "Establishment Name",
  },
  {
    key: "retailStoreName",
    label: "Retail Store Name",
  },
  {
    key: "eventName",
    label: "Event Name",
  },
];

const nameType = computed(() => {
  return nameTypes.find(({ key }) => {
    return formStore.values[key];
  });
});

const label = computed(() => {
  return nameType.value.label;
});

const name = reactiveComputed(() => {
  return formStore.values[nameType.value.key];
});

const paymentMethod = computed(() => {
  let method = "";

  if (creditDebitCard.value) {
    method = "Credit Card";
  } else if (ach.value) {
    method = "ACH";
  } else if (paypal.value) {
    method = "Paypal";
  }

  paymentStore.setPaymentMethod(method);

  return method;
});

const ebillVisible = ref(true);

const ebillingRules = computed(() =>
  ebillVisible.value
    ? {
        eBillingEmail: {
          ...emailValidation,
        },
      }
    : {}
);

const rules = {
  ...ebillingRules.value,
};

const v$ = useVuelidate(rules, values);
const isEbillingValid = ref(true);

const isValid = computed(() => {
  return pdfRead.value && paymentMethod.value && termsAndConditions.value && isEbillingValid.value && !loading.value;
});

const currentStep = computed(() => mainStore.currentStep);

const next = async () => {
  if (!isValid.value) return;

  loading.value = true;

  paymentStore.license = license.value;
  paymentStore.autopay = autopay.value;
  paymentStore.eBilling = values.eBillingEmail.value;

  try {
    await paymentStore.createClientInstance();

    try {
      await userStore.updateUserFlow(userStore.userFlow.id, currentStep.value, "PaymentView", reactive(values), false);

      setTimeout(() => {
        mainStore.setCurrentView("PaymentView");
      }, 2000);
    } catch (error) {
      alert("Something went wrong while processing your request. \nPlease contact our support team.");
      console.error(error);
      loading.value = false;
    }
  } catch (err) {
    alert("We couldn't reach the payment platform. \nPlease contact our support team.");
    loading.value = false;
  }
};

const fetchLicense = async () => {
  const { data } = await API.getLicense(userStore.userFlow?.id);
  license.value = data;
};

onMounted(async () => {
  await fetchLicense();

  const licenseStartDate = new Date(license.value?.licensePeriodStart);
  const licenseEndDate = new Date(license.value?.licensePeriodEnd);

  if (license.value?.licenseAnnualFee) {
    formattedLicenseAnnualFee.value = formatCurrency(license.value?.licenseAnnualFee);
  }

  if (license.value?.licenseFullYearFee) {
    formattedLicenseFullYearFee.value = formatCurrency(license.value?.licenseFullYearFee);
  }

  formattedLicenseStart.value = `${addLeadingZeros(licenseStartDate.getUTCMonth() + 1)}/${addLeadingZeros(
    licenseStartDate.getUTCDate()
  )}/${licenseStartDate.getUTCFullYear()}`;
  formattedLicenseEnd.value = `${addLeadingZeros(licenseEndDate.getUTCMonth() + 1)}/${addLeadingZeros(
    licenseEndDate.getUTCDate()
  )}/${licenseEndDate.getUTCFullYear()}`;

  values.eBillingEmail.value = userStore.user?.email;
});

const back = () => {
  mainStore.previousStep();
  mainStore.setCurrentView("FormView");
};

watch(arrivedState, (state) => {
  if (state.bottom) {
    pdfRead.value = true;
  }
});

onClickOutside(sampleCheck, () => {
  isCheckVisible.value = false;
});

watch(creditDebitCard, (value) => {
  if (value) {
    ach.value = false;
    paypal.value = false;
  }
});

watch(ach, (value) => {
  if (value) {
    creditDebitCard.value = false;
    paypal.value = false;
  }
});

watch(paypal, (value) => {
  if (value) {
    creditDebitCard.value = false;
    ach.value = false;
    autopay.value = false;
  }
});

watch(ebillVisible, (value) => {
  if (!value) {
    values.eBillingEmail.value = "";
    isEbillingValid.value = true;
  } else {
    values.eBillingEmail.value = userStore.user?.email;
  }
});

watch(v$, async () => {
  isEbillingValid.value = ebillVisible.value ? await v$.value.$validate() : true;
});
</script>

<template>
  <div class="xs:grid-cols-2 mb-3 grid gap-4 pt-6 sm:grid-cols-4">
    <div class="font-bold sm:leading-8">
      <p>{{ label }}</p>
      <p class="text-sm text-[#ee3d42]">{{ name }}</p>
    </div>
    <div v-if="license?.licenseAnnualFee" class="font-bold sm:leading-8">
      <p>Initial License Fee</p>
      <p class="text-sm text-[#ee3d42]">{{ formattedLicenseAnnualFee }}</p>
    </div>
    <div v-if="license?.licensePeriodStart && license?.licensePeriodEnd" class="font-bold sm:leading-8">
      <p>Initial Licensed Period</p>
      <p class="text-sm text-[#ee3d42]">{{ formattedLicenseStart }} - {{ formattedLicenseEnd }}</p>
    </div>
    <div v-if="license?.licenseFullYearFee" class="font-bold sm:leading-8">
      <p>{{ isDigitalFlow ? "Semi-Annual Rate" : "Annual Rate" }}</p>
      <p class="text-sm text-[#ee3d42]">{{ formattedLicenseFullYearFee }}</p>
    </div>
  </div>
  <p v-if="isRestaurantsFlow" class="mb-4 leading-[1.625] text-[#494949]">
    The initial licensed period you’ll be paying for today runs through
    {{ formattedLicenseEnd }}. Your license will auto-renew annually on the effective date. Future billing periods run
    July 1 - June 30 and invoices will be due July 1 of each year.
  </p>
  <p v-else-if="isDigitalFlow" class="mb-4 leading-[1.625] text-[#494949]">
    The Initial Licensed Period you’ll be paying for today runs through
    {{ formattedLicenseEnd }}. Your license will auto-renew annually on the effective date. Future billing periods run
    January 1 - June 30 and July 1 - December 31 and will be due January 1 and July 1 respectively.
  </p>
  <p v-else class="mb-4 leading-[1.625] text-[#494949]">
    The Initial Licensed Period you’ll be paying for today runs through
    {{ formattedLicenseEnd }}. Your license will auto-renew annually on the effective date. Future billing periods run
    January 1 – December 31 and invoices will be due January 1 of each year.
  </p>
  <p v-if="license?.licenseAnnualFee" class="mb-4 text-right font-bold">TOTAL DUE: {{ formattedLicenseAnnualFee }}</p>
  <div
    v-if="license?.licenseUrl"
    ref="pdf"
    class="relative mb-4 overflow-auto border-[1px] border-solid border-[#494949]/20 pt-[49.89495798%]"
  >
    <VuePdfEmbed
      :source="license.licenseUrl"
      :disable-text-layer="true"
      :disable-annotation-layer="true"
      class="absolute top-0 left-0 right-0"
    />
  </div>
  <p class="mb-8 text-right text-xs font-bold text-[#ee3d42]">You must scroll to the bottom before proceeding</p>
  <div class="mb-7 grid gap-5" :class="{ 'pointer-events-none': !pdfRead }">
    <FormCheckbox
      v-model="termsAndConditions"
      :disabled="!pdfRead"
      class="flex-row-reverse !items-start justify-items-end text-right sm:!items-center"
      >I agree to the terms & conditions of the SESAC License Agreement</FormCheckbox
    >
    <FormCheckbox
      v-model="paypal"
      :disabled="!pdfRead || !termsAndConditions"
      class="flex-row-reverse justify-items-end text-right"
      >Paypal</FormCheckbox
    >
    <FormCheckbox
      v-model="creditDebitCard"
      :disabled="!pdfRead || !termsAndConditions"
      class="flex-row-reverse justify-items-end text-right"
      >Credit/Debit Card</FormCheckbox
    >
    <FormCheckbox
      v-model="ach"
      :disabled="!pdfRead || !termsAndConditions"
      class="flex-row-reverse !items-start justify-items-end text-right"
      >ACH/e-check
      <div ref="sampleCheck" class="relative block">
        <button class="text-xs text-[#ee3d42] underline" @click="isCheckVisible = !isCheckVisible">
          view sample check
        </button>
        <img
          class="absolute top-full right-0 z-10 hidden w-[335px] max-w-[500px] md:top-1/2 md:right-full md:w-[500px] md:-translate-y-1/2"
          :class="{
            '!block': pdfRead && isCheckVisible && termsAndConditions,
          }"
          :src="checkExample"
          alt=""
        />
      </div>
    </FormCheckbox>
  </div>
  <TheButtonWrapper class="justify-between">
    <TheButton kind="black-border" @click="back">Back</TheButton>
    <div class="text-right">
      <TheButton kind="red" :disabled="!isValid" @click="next">
        <span v-if="!loading">Purchase</span>
        <div v-else class="flex items-center justify-center">
          <div
            class="mr-2 h-6 w-6 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-400 ease-linear"
          ></div>
          <span class="text-center text-xl font-semibold text-white">Processing...</span>
        </div>
      </TheButton>
      <FormCheckbox
        v-model="autopay"
        :disabled="!pdfRead || !termsAndConditions || paypal"
        class="mt-3 flex-row-reverse justify-items-end text-right"
        >Autopay Enrolled</FormCheckbox
      >
      <FormCheckbox
        v-model="ebillVisible"
        :disabled="!pdfRead || !termsAndConditions"
        class="mt-3 flex-row-reverse justify-items-end text-right"
        >eBilling Enrolled</FormCheckbox
      >
      <FormInput
        v-if="ebillVisible"
        v-model="values.eBillingEmail.value"
        class="mt-3"
        type="email"
        placeholder="EBill"
        :disabled="loading"
      ></FormInput>
    </div>
  </TheButtonWrapper>
</template>
