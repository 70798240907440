<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const clickHandler = () => {
  if (props.disabled) return;

  emit("update:modelValue", !props.modelValue);
};
</script>

<template>
  <label
    class="flex items-center gap-4"
    :class="{
      'opacity-40': props.disabled,
    }"
  >
    <button
      type="button"
      class="relative block h-5 w-5 flex-shrink-0 bg-[#F5F5F5] bg-[length:55%] bg-center bg-no-repeat focus-visible:outline-[#d43da5]"
      :class="{ checkbox: modelValue, 'cursor-not-allowed': props.disabled }"
      @click="clickHandler"
    ></button>
    <span class="text-[#494949]">
      <slot />
    </span>
  </label>
</template>

<style scoped>
.checkbox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3E%3Cpath d='M10.692 0a.783.783 0 0 0-.53.245L4.17 6.474l-2.829-2.61a.783.783 0 1 0-1.06 1.15l3.392 3.13a.783.783 0 0 0 1.092-.032l6.522-6.782A.783.783 0 0 0 10.692 0Z'/%3E%3C/svg%3E");
}
</style>
