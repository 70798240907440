<script setup>
import { inHomeFitnessValues } from "~/apps/stores/form/in-home-fitness";
import { webAppValues } from "~/apps/stores/form/web-app";
import { formatDate } from "~/utils/common";

import FormInput from "./FormInput.vue";
import FormTip from "./FormTip.vue";

const props = defineProps({
  flow: {
    type: String,
    default: "Web App",
  },
});

const values = props.flow === "Web App" ? webAppValues : inHomeFitnessValues;
</script>

<template>
  <div class="mt-4">
    <div class="hidden grid-cols-5 gap-[1px] bg-[#494949]/30 p-[1px] pb-0 text-center text-sm text-[#494949] md:grid">
      <div class="bg-white p-3.5 font-bold">License Fee Period</div>
      <div class="bg-white p-3.5 font-bold">Designated Period</div>
      <div class="bg-white p-3.5 font-bold">
        Distribution Revenue for Designated Period
        <FormTip>
          <p>
            "Distribution Revenue" means any and all Revenue in connection with the provision of access to the Licensed
            Service of any of the content made available thereby, including, without limitation, subscription or
            transactional fees. In the event LICENSEE offers an "Extended Trial Period" or a "Bundle" the following
            shall apply:
          </p>
          <ol>
            <li>
              "Extended Trial Period" means a free or discounted trial period to a subscription tier of the Licensed
              Service for a period longer than thirty (30) days. In such event, for each end user receiving an Extended
              Tirl Period, the amount to be included as Distribution Revenue after such thirst (30) day period shall be:
              (x) the average standalone retail price of such tier; or (y) if applicable, the amount determined pursuant
              to paragraph 2. C. ii., below.
            </li>
            <li>
              "Bundle" means instances where the Licensed Service is bundled with other product(s) or service(s) for a
              single price. In such event, the amount to be included as Distribution Revenue shall be the total retail
              price of the Bundle multiplied by a fraction, the numerator of which is the standalone retail price of the
              Licensed Service and the denominator of which is the sum of all standalone retail prices of all components
              of the Bundle; provided that, if there is no standalone published price for a component of the Bundle,
              then LICENSEE shall use the average standalone published price for end users for the most closely
              comparable product or service in the U.S., or, if more than one comparable exists, the average of the
              standalone prices for such comparables.
            </li>
          </ol>
        </FormTip>
      </div>
      <div class="bg-white p-3.5 font-bold">
        Advertising Revenue for Designated Period
        <FormTip
          >"Advertising Revenue" means any and all Revenue in connection with advertising, sponsorship or promotional
          materials by means of the Licensed Service (including, without limitation, any and all display advertisements
          placed on any of the pages of the Licensed Properties as well as any and all "in-stream"
          advertisements.</FormTip
        >
      </div>
      <div class="bg-white p-3.5 font-bold">
        Other Revenue
        <FormTip
          >"Other Revenue" means any and all Revenue other than Distribution Revenue and Advertising Revenue, including
          any and all donations made in connection with the Licensed Service.</FormTip
        >
      </div>
    </div>
    <div
      v-for="(period, index) in values.distributionDesignatedPeriods.value"
      :key="index"
      class="mb-4 grid gap-[1px] bg-[#494949]/30 p-[1px] text-center text-sm text-[#494949] md:mb-0 md:grid-cols-5 md:pb-0 md:last:pb-[1px]"
    >
      <p class="bg-white p-3.5 text-xs before:block before:content-['License_Fee_Period:'] md:before:content-none">
        {{ formatDate(period.feePeriodStart) }} - {{ formatDate(period.feePeriodEnd) }}
      </p>
      <p class="bg-white p-3.5 text-xs before:block before:content-['Designated_Period:'] md:before:content-none">
        {{ formatDate(period.periodStart) }} - {{ formatDate(period.periodEnd) }}
      </p>
      <p
        class="bg-white p-3.5 before:block before:content-['Distribution_Revenue_for_Designated_Period:'] md:p-0 md:before:content-none"
      >
        <FormInput
          v-model="period.distributionRevenue"
          class="bg-white text-center"
          type="text"
          kind="number"
          full-width
          placeholder="0"
        />
      </p>
      <p
        class="bg-white p-3.5 before:block before:content-['Advertising_Revenue_for_Designated_Period:'] md:p-0 md:before:content-none"
      >
        <FormInput
          v-model="period.advertisingRevenue"
          class="bg-white text-center"
          type="text"
          kind="number"
          full-width
          placeholder="0"
        />
      </p>
      <p class="bg-white p-3.5 before:block before:content-['Other_Revenue:'] md:p-0 md:before:content-none">
        <FormInput
          v-model="period.otherRevenue"
          class="bg-white text-center"
          type="text"
          kind="number"
          full-width
          placeholder="0"
        />
      </p>
    </div>
  </div>
</template>
