<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, numeric } from "@vuelidate/validators";
import { requiredValidation } from "@/utils/validations";
import { numericDecimalOnly, numericOnly } from "@/utils/common";

import useMainStore from "@/apps/stores/main";
import useFormStore from "@/apps/stores/form";

import { hotelsValues } from "@/apps/stores/form/hotels";

import FormWrapper from "@/apps/components/Form/FormWrapper.vue";
import FormItem from "@/apps/components/Form/FormItem.vue";
import FormInput from "@/apps/components/Form/FormInput.vue";
import FormSelect from "@/apps/components/Form/FormSelect.vue";
import FormDatepicker from "@/apps/components/Form/FormDatepicker.vue";
import FormTip from "@/apps/components/Form/FormTip.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const haveLiveMusicOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const recordedMusicOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const musicOnWebsiteOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const ahlaMemberOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];

const isMemberIdVisible = computed(() => hotelsValues.ahlaMember.value === "Yes");

const memberIdRules = computed(() =>
  isMemberIdVisible.value
    ? {
        ahlaMemberId: {
          required: requiredValidation("Member ID"),
        },
      }
    : {}
);

const atLeastOneYesRule = () => {
  return (
    hotelsValues.haveLiveMusic.value === "Yes" ||
    hotelsValues.recordedMusic.value === "Yes" ||
    hotelsValues.musicOnWebsite.value === "Yes"
  );
};

const rules = computed(() => ({
  haveLiveMusic: {
    required: requiredValidation("", "Do You Have Live Music?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  recordedMusic: {
    required: requiredValidation("", "Do You Use Recorded Music?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  musicOnWebsite: {
    required: requiredValidation("", "Do You Have Music On Your Website?"),
    atLeastOneYes: helpers.withMessage(
      `At least one of these must be yes: <br> Do You Have Live Music? <br> Do You Use Recorded Music? <br> Do You Have Music On Your Website?`,
      atLeastOneYesRule
    ),
  },
  ahlaMember: {
    required: requiredValidation("", "Are You an AHLA Member?"),
  },
  ...memberIdRules.value,
  numberOfRooms: {
    required: requiredValidation("Number Of Rooms"),
    numeric: helpers.withMessage("Number Of Rooms can only contain digits.", numeric),
  },
  averagePricePerRoom: {
    required: requiredValidation("Average Daily Rate"),
    numeric: helpers.withMessage("Average Daily Rate can only contain digits.", numeric),
  },
  hotelMusicStartDate: {
    required: requiredValidation("Hotel Music Start Date"),
  },
}));

const v$ = useVuelidate(rules, hotelsValues, { $autoDirty: true });
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("MusicInYourEstablishment");
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem>
      <template #label>Do you have live music?</template>
      <template #tip>
        <FormTip>
          Whether provided directly by the property or indirectly by customers hosting events such as meetings,
          conferences, banquets, and parties, live music authorization is needed.
        </FormTip>
      </template>
      <template #field>
        <FormSelect v-model="hotelsValues.haveLiveMusic.value" full-width :options="haveLiveMusicOptions" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Do you use recorded music?</template>
      <template #tip>
        <FormTip>
          <p>Example: DJ, Karaoke, Streaming, Digital, TV, DVDs, Radio.</p>
        </FormTip>
      </template>
      <template #field>
        <FormSelect v-model="hotelsValues.recordedMusic.value" :options="recordedMusicOptions" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Do you have music on your website?</template>
      <template #field>
        <FormSelect v-model="hotelsValues.musicOnWebsite.value" :options="musicOnWebsiteOptions" />
      </template>
    </FormItem>
    <FormItem :capitalize="false">
      <template #label>Are You an AHLA Member?</template>
      <template #field>
        <FormSelect v-model="hotelsValues.ahlaMember.value" :options="ahlaMemberOptions" />
      </template>
    </FormItem>
    <FormItem v-if="isMemberIdVisible">
      <template #label>Member ID*</template>
      <template #field>
        <FormInput v-model="hotelsValues.ahlaMemberId.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Number of Rooms*</template>
      <template #field>
        <FormInput v-model="hotelsValues.numberOfRooms.value" type="number" required @keydown="numericOnly" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Average Daily Rate*</template>
      <template #tip>
        <FormTip
          >To calculate “ADR” take the total room revenue for the Premises during the applicable Reporting Period
          divided by the aggregate total number of days each room was rented during such Reporting Period. As the
          deadline to Report is October 1, you would base this on revenue and activity occurring between September 1
          year prior through September 30 of current year.</FormTip
        >
      </template>
      <template #field>
        <FormInput
          v-model="hotelsValues.averagePricePerRoom.value"
          type="number"
          required
          @keydown="numericDecimalOnly"
        />
      </template>
    </FormItem>
    <FormItem>
      <template #label>When did your hotel start using music?*</template>
      <template #field>
        <FormDatepicker v-model="hotelsValues.hotelMusicStartDate.value" month-picker required />
      </template>
    </FormItem>
  </FormWrapper>
</template>
