import { computed, ref } from "vue";
import { defineStore } from "pinia";

import useFlowsStore from "../flows";

import { userValues } from "./user";
import { restaurantsValues } from "./restaurants";
import { hotelsValues } from "./hotels";
import { otherValues } from "./other";
import { retailStoreValues } from "./retail-store";
import { specialEventsValues } from "./special-events";
import { countryClubsValues } from "./country-clubs";
import { radioValues } from "./radio";
import { residentialFacilityValues } from "./residential-facility";
import { fitnessCenterValues } from "./fitness-center";
import { webAppValues } from "./web-app";
import { inHomeFitnessValues } from "./in-home-fitness";

const formValues = {
  Restaurants: restaurantsValues,
  Hotels: hotelsValues,
  Other: otherValues,
  "Retail Store": retailStoreValues,
  "Special Events": specialEventsValues,
  "Country Clubs": countryClubsValues,
  Radio: radioValues,
  "Residential Facility": residentialFacilityValues,
  "Fitness Center": fitnessCenterValues,
  "Web App": webAppValues,
  "In-Home Fitness": inHomeFitnessValues,
};

const useFormStore = defineStore("form", () => {
  const flowsStore = useFlowsStore();
  const user = ref(userValues);
  const values = computed(() => formValues[flowsStore.currentFlow.name]);
  const errors = ref([]);

  const addErrors = (items) => {
    errors.value = [...items];
  };

  return { user, values, errors, addErrors };
});

export default useFormStore;
