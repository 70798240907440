import axios from "axios";

const API = (url = window.location.origin) => {
  return axios.create({
    baseURL: `${url}/api`,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
  });
};

const BTL = () => {
  return axios.create({
    baseURL: import.meta.env.VITE_SESAC_BTL_API,
  });
};

const FTL = () => {
  return axios.create({
    baseURL: import.meta.env.VITE_SESAC_FTL_API,
  });
};

export default {
  getCurrentUser() {
    return API().get("/current_user");
  },
  getFlows() {
    return API().get("/flows");
  },
  createUserFlow(flowId, currentStep, currentView, userValues, flowValues, completed = false) {
    return API().post("/user_flows", {
      user: userValues,
      userFlow: {
        flowId,
        currentStep,
        currentView,
        values: flowValues,
        completed,
      },
    });
  },
  updateUserFlow(userFlowId, currentStep, currentView, values, completed = false) {
    return API().put(`/user_flows/${userFlowId}`, {
      currentStep,
      currentView,
      values,
      completed,
    });
  },
  getMostRecentUserFlow() {
    return API().get("/most_recent_user_flow");
  },
  getLicense(id) {
    return API().get(`/user_flows/${id}/license`);
  },
  contactLicense(id) {
    return API().post(`/user_flows/${id}/contact`);
  },
  getBraintreeToken() {
    return BTL().get("/client-token");
  },
  postTransaction(nonce, deviceData, fee, email, paymentMethod, autopay, recaptcha) {
    return BTL().post("/transaction", {
      payment_method_nonce: nonce,
      device_data: deviceData,
      amount: fee,
      email: email,
      payment_method_type: paymentMethod,
      autopay_flag: autopay,
      recaptcha_token: recaptcha,
    });
  },
  completeLicense(licenseId, transactionId, autopay, paymentMethodToken, paymentMethod, eBilling) {
    return FTL().put(`/license/${licenseId}/complete/braintree`, {
      transaction_id: transactionId,
      autopay: autopay,
      payment_method_token: paymentMethodToken,
      payment_method_type: paymentMethod,
      ebilling: eBilling,
    });
  },
};
