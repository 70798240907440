<script setup>
import { computed, onMounted } from "vue";

import useMainStore from "./stores/main";
import useFlowsStore from "./stores/flows";
import useUserStore from "./stores/user";

import TheHeading from "./components/TheHeading.vue";
import ProgressBar from "./components/ProgressBar.vue";
import TheSubheading from "./components/TheSubheading.vue";
import TheDescription from "./components/TheDescription.vue";

import LicenseTypeView from "./views/LicenseTypeView.vue";
import ThankYouView from "./views/ThankYouView.vue";
import FormView from "./views/FormView.vue";
import AnnualLicenseView from "./views/AnnualLicenseView.vue";
import PaymentView from "./views/PaymentView.vue";
import PaymentSuccessfulView from "./views/PaymentSuccessfulView.vue";
import LicenseUncompletedView from "./views/LicenseUncompletedView.vue";

const mainStore = useMainStore();
const flowsStore = useFlowsStore();
const userStore = useUserStore();

const viewMap = {
  LicenseTypeView,
  ThankYouView,
  FormView,
  AnnualLicenseView,
  PaymentView,
  PaymentSuccessfulView,
  LicenseUncompletedView,
};

const isNotPaymentSuccessfulView = computed(
  () => !/PaymentSuccessfulView|LicenseUncompletedView/.test(mainStore.currentView)
);

onMounted(async () => {
  await flowsStore.getFlows();
  await userStore.getCurrentUser();
  await userStore.getUserFlow();
});
</script>

<template>
  <div class="app grid min-h-screen place-items-center bg-[#EBEBEB]">
    <div class="container max-w-screen-md bg-white py-10">
      <TheHeading v-if="isNotPaymentSuccessfulView" />
      <ProgressBar v-if="isNotPaymentSuccessfulView" />
      <TheSubheading />
      <TheDescription />
      <Component :is="viewMap[mainStore.currentView]" />
    </div>
  </div>
</template>

<style>
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
</style>
