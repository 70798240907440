<script setup>
import { onMounted } from "vue";
import useMainStore from "@/apps/stores/main";
import usePaymentStore from "~/apps/stores/payment";

const mainStore = useMainStore();
const paymentStore = usePaymentStore();

onMounted(async () => {
  const licenseId = await paymentStore.getLicenseId();
  mainStore.setDescription(
    `Please contact our support team informing them your license ID ( <strong>${licenseId}</strong> ) and the Authorization Code you received on your email.`
  );
});
</script>
