<template>
  <div>
    <div class="text-center text-sm text-[#494949]">
      <div class="hidden grid-cols-2 gap-[1px] bg-[#494949]/30 px-[1px] pt-[1px] md:grid">
        <p class="bg-white p-3 font-bold">License Fee Period</p>
        <p class="bg-white p-3 font-bold">Designated Period</p>
      </div>
      <div class="mb-4 grid gap-[1px] bg-[#494949]/30 p-[1px] md:mb-0 md:grid-cols-2 md:pb-0">
        <p class="bg-white p-3 before:block before:content-['License_Fee_Period:'] md:before:content-none">
          January 1 - June 30
        </p>
        <p class="bg-white p-3 before:block before:content-['Designated_Period:'] md:before:content-none">
          Immediately Previous May 1 - October 31
        </p>
      </div>
      <div class="grid gap-[1px] bg-[#494949]/30 p-[1px] md:grid-cols-2">
        <p class="bg-white p-3 before:block before:content-['License_Fee_Period:'] md:before:content-none">
          July 1 - December 31
        </p>
        <p class="bg-white p-3 before:block before:content-['Designated_Period:'] md:before:content-none">
          Immediately Previous November 1 - April 30
        </p>
      </div>
    </div>
  </div>
</template>
