<script setup>
import { computed } from "vue";

import useMainStore from "../stores/main";

const mainStore = useMainStore();

const subheading = computed(() => mainStore.subheading);
</script>

<template>
  <h2 v-if="subheading" class="mb-3 text-4xl">
    {{ subheading }}
  </h2>
</template>
