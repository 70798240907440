<script setup>
import { onMounted } from "vue";
import useMainStore from "@/apps/stores/main";
import useFlowStore from "@/apps/stores/flows";

import TheButtonWrapper from "@/apps/components/TheButtonWrapper.vue";
import TheButton from "@/apps/components/TheButton.vue";

const mainStore = useMainStore();
const flowStore = useFlowStore();

const back = () => {
  if (flowStore.currentFlow.name == "Other") {
    window.open("https://www.sesac.com/", "_self");
  } else {
    mainStore.previousStep();
    mainStore.setCurrentView("FormView");
  }
};

onMounted(() => {
  mainStore.setDescription("We will have one of our Licensing Representatives contact you within 24 hours.");
  mainStore.setStepCount(mainStore.currentStep);
});
</script>

<template>
  <TheButtonWrapper class="mt-10 justify-start">
    <TheButton kind="black-border" @click="back">Back</TheButton>
  </TheButtonWrapper>
</template>
