<script setup>
import { computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { requiredValidation, zipcodeValidation } from "@/utils/validations";
import { getState, stateAbbreviationsArray } from "@/utils/common";

import useMainStore from "@/apps/stores/main";
import useFormStore from "@/apps/stores/form";

import { webAppValues } from "@/apps/stores/form/web-app";

import FormWrapper from "@/apps/components/Form/FormWrapper.vue";
import FormItem from "@/apps/components/Form/FormItem.vue";
import FormInput from "@/apps/components/Form/FormInput.vue";
import FormSelect from "@/apps/components/Form/FormSelect.vue";

const mainStore = useMainStore();
const formStore = useFormStore();

const similarBillingAddressOptions = [
  {
    value: "",
    disabled: true,
  },
  {
    value: "Yes",
  },
  {
    value: "No",
  },
];
const isSimilarBillingAddress = computed(() => {
  return webAppValues.similarBillingAddress.value.length === 0 || webAppValues.similarBillingAddress.value === "Yes";
});

const billingRules = computed(() =>
  !isSimilarBillingAddress.value
    ? {
        billingStreetAddress: {
          required: requiredValidation("Billing Street Address"),
        },
        billingCity: {
          required: requiredValidation("Billing City"),
        },
        billingState: {
          required: requiredValidation("Billing State"),
        },
        billingZipcode: {
          ...zipcodeValidation("Billing Zip Code"),
        },
      }
    : {}
);

const rules = computed(() => ({
  websiteAppName: {
    required: requiredValidation("Website/App Name"),
  },
  legalEntityName: {
    required: requiredValidation("Legal Entity Name"),
  },
  legalEntityAddress: {
    required: requiredValidation("Legal Entity Address"),
  },
  city: {
    required: requiredValidation("City"),
  },
  state: {
    required: requiredValidation("State"),
  },
  zipcode: {
    ...zipcodeValidation("Legal Entity Zip Code"),
  },
  similarBillingAddress: {
    required: requiredValidation("", "Is Your Billing Address The Same As Your Establishment?"),
  },
  ...billingRules.value,
}));

const v$ = useVuelidate(rules, webAppValues);
const validate = () => v$.value.$validate();

defineExpose({
  validate,
});

watch(v$, async (values) => {
  formStore.addErrors([values.$errors.map((error) => error.$message)[0]]);
});

onMounted(() => {
  mainStore.setSubheading("TellUsAboutYourWebApp");

  if (!webAppValues.state.value) {
    webAppValues.state.value = getState(webAppValues.zipcode.value);
  }
});
</script>

<template>
  <FormWrapper class="mb-10">
    <FormItem :capitalize="false" class="sm:!col-span-12">
      <template #label>Name of Service Mark (Website or App Name)*</template>
      <template #field>
        <FormInput v-model="webAppValues.websiteAppName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Legal Entity Name*</template>
      <template #field>
        <FormInput v-model="webAppValues.legalEntityName.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Legal Entity Address*</template>
      <template #field>
        <FormInput v-model="webAppValues.legalEntityAddress.value" type="text" required />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Address Line 2</template>
      <template #field>
        <FormInput v-model="webAppValues.addressLine2.value" type="text" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>City*</template>
      <template #field>
        <FormInput v-model="webAppValues.city.value" type="text" required />
      </template>
    </FormItem>
    <FormItem class="sm:col-span-2">
      <template #label>State*</template>
      <template #field>
        <FormSelect
          v-model="webAppValues.state.value"
          :options="[{ label: 'State', value: '' }, ...stateAbbreviationsArray]"
        />
      </template>
    </FormItem>
    <FormItem class="sm:col-span-4">
      <template #label>Legal Entity Zip Code*</template>
      <template #field>
        <FormInput v-model="webAppValues.zipcode.value" type="text" kind="zipcode" required pattern="[0-9]*" />
      </template>
    </FormItem>
    <FormItem>
      <template #label>Is your billing address the same as your Legal Entity?</template>
      <template #field>
        <FormSelect v-model="webAppValues.similarBillingAddress.value" :options="similarBillingAddressOptions" />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-12">
      <template #label>Billing Street Address*</template>
      <template #field>
        <FormInput v-model="webAppValues.billingStreetAddress.value" full-width type="text" required />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress">
      <template #label>Billing City*</template>
      <template #field>
        <FormInput v-model="webAppValues.billingCity.value" type="text" required />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-2">
      <template #label>Billing State*</template>
      <template #field>
        <FormSelect
          v-model="webAppValues.billingState.value"
          :options="[{ label: 'State', value: '' }, ...stateAbbreviationsArray]"
        />
      </template>
    </FormItem>
    <FormItem v-if="!isSimilarBillingAddress" class="sm:col-span-4">
      <template #label>Billing Zip Code*</template>
      <template #field>
        <FormInput v-model="webAppValues.billingZipcode.value" type="text" kind="zipcode" required pattern="[0-9]*" />
      </template>
    </FormItem>
  </FormWrapper>
</template>
