<script setup>
import { computed, ref } from "vue";
import { nextStep } from "~/utils/common";

import useMainStore from "~/apps/stores/main";
import useUserStore from "~/apps/stores/user";
import useFlowsStore from "~/apps/stores/flows";

import { hotelsValues } from "~/apps/stores/form/hotels";

import TheButton from "~/apps/components/TheButton.vue";
import TheButtonWrapper from "~/apps/components/TheButtonWrapper.vue";
import FormErrors from "~/apps/components/Form/FormErrors.vue";

import TellUsAboutYourself from "./Hotels/TellUsAboutYourself.vue";
import TellUsAboutYourEstablishment from "./Hotels/TellUsAboutYourEstablishment.vue";
import MusicInYourEstablishment from "./Hotels/MusicInYourEstablishment.vue";

const components = {
  1: TellUsAboutYourself,
  2: TellUsAboutYourEstablishment,
  3: MusicInYourEstablishment,
};

const mainStore = useMainStore();
const userStore = useUserStore();
const flowsStore = useFlowsStore();

const currentStep = computed(() => mainStore.currentStep);

const component = ref(null);

const back = () => {
  mainStore.previousStep();
};

const next = async () => {
  const isValid = await component.value.validate();

  if (!isValid) return;

  await nextStep(hotelsValues, +hotelsValues.locationsNumber.value > 1);
};
</script>

<template>
  <Component :is="components[currentStep - 1]" ref="component" />
  <FormErrors />
  <TheButtonWrapper>
    <TheButton @click="back">Back</TheButton>
    <TheButton kind="red" @click="next">Next Step</TheButton>
  </TheButtonWrapper>
</template>
