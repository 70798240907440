import { reactive } from "vue";
import { defineStore } from "pinia";
import isEmpty from "lodash/isEmpty";

import API from "../../../API";

import useMainStore from "../main";
import useFlowsStore from "../flows";

import { userValues } from "../form/user";
import { restaurantsValues } from "../form/restaurants";
import { hotelsValues } from "../form/hotels";
import { otherValues } from "../form/other";
import { retailStoreValues } from "../form/retail-store";
import { specialEventsValues } from "../form/special-events";
import { countryClubsValues } from "../form/country-clubs";
import { radioValues } from "../form/radio";
import { residentialFacilityValues } from "../form/residential-facility";
import { fitnessCenterValues } from "../form/fitness-center";
import { webAppValues } from "../form/web-app";
import { inHomeFitnessValues } from "../form/in-home-fitness";

const valuesMap = {
  Restaurants: restaurantsValues,
  Hotels: hotelsValues,
  Other: otherValues,
  "Retail Store": retailStoreValues,
  "Special Events": specialEventsValues,
  "Country Clubs": countryClubsValues,
  Radio: radioValues,
  "Residential Facility": residentialFacilityValues,
  "Fitness Center": fitnessCenterValues,
  "Web App": webAppValues,
  "In-Home Fitness": inHomeFitnessValues,
};

const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    userFlow: null,
  }),
  actions: {
    async getCurrentUser() {
      const { data } = await API.getCurrentUser();

      if (isEmpty(data)) return;

      this.user = data;

      userValues.email.value = data.email;
      userValues.firstName.value = data.firstName;
      userValues.lastName.value = data.lastName;
      userValues.phoneNumber.value = data.phoneNumber;
      userValues.title.value = data.title;
    },
    async getUserFlow() {
      const { data } = await API.getMostRecentUserFlow();

      if (isEmpty(data)) return;

      const mainStore = useMainStore();
      const flowsStore = useFlowsStore();
      const flows = flowsStore.flows;

      const flowId = data.flowId;
      const currentFlow = flowId ? flows.filter((flow) => flow.id === flowId)[0] : null;
      const currentFlowName = currentFlow ? currentFlow.name : null;

      const currentValues = reactive(valuesMap[currentFlowName]);

      Object.keys(currentValues).forEach((key) => {
        if (typeof currentValues[key] === "object") {
          if (Array.isArray(currentValues[key])) {
            currentValues[key] = data.values[key];

            return;
          }

          Object.keys(currentValues[key]).forEach((subKey) => {
            currentValues[key][subKey] = data.values[key][subKey];
          });

          return;
        }

        currentValues[key] = data.values[key];
      });

      flowsStore.setCurrentFlow(currentFlow);
      mainStore.setCurrentStep(data.currentStep);
      mainStore.setCurrentView(data.currentView);

      this.userFlow = data;
    },
    async createUserFlow(flowId, currentStep, currentView, userValues, flowValues, completed = false) {
      const response = await API.createUserFlow(flowId, currentStep, currentView, userValues, flowValues, completed);
      this.userFlow = response?.data;

      await this.getCurrentUser();

      return response;
    },
    async updateUserFlow(userFlowId, currentStep, currentView, values, completed = false) {
      const response = await API.updateUserFlow(userFlowId, currentStep, currentView, values, completed);
      this.userFlow = response?.data;

      return response;
    },
  },
});

export default useUserStore;
