<script setup>
import { onMounted } from "vue";
import useMainStore from "@/apps/stores/main";

const mainStore = useMainStore();

onMounted(() => {
  mainStore.setDescription(
    "A SESAC Licensing coordinator will confirm receipt within 72 hours to the email address provided in this submission."
  );
});
</script>
